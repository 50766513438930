import {
  Button,
  Input,
  Loader,
  Modal,
  Notification,
  NumberInput,
  Textarea,
} from "@mantine/core";
import NavBar from "../navbar/NavBar";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import generateRandomString from "../../utility/generateRandomString";

function DespesasInicio() {
  const token = window.localStorage.getItem("x-access-token");

  const [opened, { open, close }] = useDisclosure(false);
  const [projetos, setProjetos] = useState([]);
  const [projetosFiltro, setProjetosFiltro] = useState([]);
  const [descricao, setDescricao] = useState("");
  const [data, setData] = useState("");
  const [observacao, setObservacao] = useState("");

  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [numero, setNumero] = useState(0);
  const [uf, setUf] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingAdicionar, setLoadingAdicionar] = useState(false);

  const navigate = useNavigate();

  function getProjetos() {
    axios({
      method: "get",
      url: "https://api-scaf.onrender.com/api/projetos",
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setProjetos(res.data);
        setProjetosFiltro(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar usuários !", "", "error");
      });
  }

  function AdicionarProjeto() {
    if (!descricao) {
      return Swal.fire(
        "Uma descrição ser informada !",
        "",
        "error"
      );
    }
    if (!data) {
      return Swal.fire(
        "Uma data ser informada !",
        "",
        "error"
      );
    }
    setLoadingAdicionar(true);
    axios({
      method: "post",
      url: "https://api-scaf.onrender.com/api/projetos",
      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        descricao,
        data,
        observacao,
        cep,
        logradouro,
        bairro,
        municipio,
        numero,
        uf,
      },
    })
      .then((res) => {
        getProjetos();
        setLoadingAdicionar(false);
        setCep("");
        setObservacao("");
        setData("");
        setDescricao("");
        setLogradouro("");
        setBairro("");
        setMunicipio("");
        setNumero("");
        setUf("");
        Swal.fire("Projeto Cadastrado com sucesso !", "", "success");
      })
      .catch((err) => {
        setLoadingAdicionar(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        console.log(err);
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao cadastrar projeto !", "", "error");
      });
  }

  function deletarProjeto(id) {
    var msg = generateRandomString();
    var confim = prompt(`Digite os caracteres para excluir: ${msg} `);
    if (confim !== msg) {
      setLoading(false);
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    if (confirm("Deseja excluir o cadastro selecionado ? Essa ação não poderá ser recuperada !") === false) {
      setLoading(false);
      return;
    }
    console.log(id);
    axios({
      method: "delete",
      url: `https://api-scaf.onrender.com/api/Projetos/${id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        getProjetos();
        setLoading(false);

        return Swal.fire(`Projeto excluido com sucesso`, "", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }

  const handleRowClick = (row) => {
    navigate(`/despesas/${row.id}`);
  };

  useEffect(() => {
    getProjetos();
  }, []);

  const columns = [
    {
      name: "Data",
      selector: (row) =>
        new Date(row.data).toLocaleString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour12: false,
        }),
      filter: true,
      sortable: true,
    },
    {
      name: "Descrição",
      selector: (row) => row.descricao,
      filter: true,
      sortable: true,
    },
    {
      name: "Bairro",
      selector: (row) => row.bairro,
      filter: true,
      sortable: true,
    },
    {
      name: "Município",
      selector: (row) => row.municipio,
      filter: true,
      sortable: true,
    },
    {
      name: "Observação",
      selector: (row) => row.observacao,
      filter: true,
      sortable: true,
    },
    {
      cell: (row) => (
        <div>
          <button
            className="btn btn-danger ms-1"
            onClick={(e) => deletarProjeto(row.id)}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleCep = (value) => {
    value = value.replace(/\D/g, "");
    setCep(value);
    if (value.length >= 8) {
      axios({
        method: "get",
        url: `https://opencep.com/v1/${value}`,
      })
        .then((res) => {
          setLogradouro(res.data.logradouro);
          setBairro(res.data.bairro);
          setMunicipio(res.data.localidade);
          setNumero(res.data.numero);
          setUf(res.data.uf);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    setCep("");
    setObservacao("");
    setData("");
    setDescricao("");
    setLogradouro("");
    setBairro("");
    setMunicipio("");
    setNumero("");
    setUf("");
  }, [opened]);

  async function pesquisar(value) {
    value = value.toUpperCase();
    let filteredProject;
    try {
      filteredProject = projetos.filter(
        (projetos) =>
          (projetos.data &&
            new Date(projetos.data)
              .toLocaleString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              })
              .includes(value)) ||
          (projetos.descricao &&
            projetos.descricao.toUpperCase().includes(value)) ||
          (projetos.bairro && projetos.bairro.toUpperCase().includes(value)) ||
          (projetos.municipio &&
            projetos.municipio.toUpperCase().includes(value)) ||
          (projetos.observacao &&
            projetos.observacao.toUpperCase().includes(value))
      );

      setProjetosFiltro(filteredProject);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div>
      <NavBar />

      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <h4>Projetos ({projetosFiltro.length})</h4>
        <div className="row my-3">
          <div className="col">
            <Button
              leftSection={<IconPlus size={14} />}
              variant="filled"
              onClick={open}
              color="rgba(54, 194, 41, 1)"
            >
              Adicionar Novo
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input
              leftSection={<IconSearch size={16} />}
              onChange={(e) => pesquisar(e.target.value)}
            />
          </div>
        </div>
        {loading ? (
          <div className="text-center mt-3">
            <Loader color="green" />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={projetosFiltro}
            onRowClicked={handleRowClick}
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
            highlightOnHover
            pointerOnHover
          />
        )}
      </div>
      <Modal opened={opened} onClose={close} title="Adicionar Novo">
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Descrição:" withAsterisk>
              <Input
                onChange={(e) => setDescricao(e.target.value)}
                value={descricao}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Data:" withAsterisk>
              <input
                className="form-control"
                type="date"
                name=""
                id=""
                onChange={(e) => setData(e.target.value)}
                value={data}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <Input.Wrapper label="CEP:">
              <Input onChange={(e) => handleCep(e.target.value)} value={cep} />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Logradouro:">
              <Input
                onChange={(e) => setLogradouro(e.target.value)}
                value={logradouro}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Bairro:">
              <Input
                onChange={(e) => setBairro(e.target.value)}
                value={bairro}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Município:">
              <Input
                onChange={(e) => setMunicipio(e.target.value)}
                value={municipio}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Número:">
              <Input
                onChange={(e) => setNumero(e.target.value)}
                value={numero}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="UF:">
              <Input onChange={(e) => setUf(e.target.value)} value={uf} />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Textarea
              label="Observação:"
              onChange={(e) => setObservacao(e.target.value)}
              value={observacao}
            />
            {loadingAdicionar ? (
              <Button
                leftSection={<IconPlus size={14} />}
                className="mt-3"
                onClick={AdicionarProjeto}
                fullWidth
                loading
                size="xs"
                color="rgba(54, 194, 41, 1)"
              >
                Adicionar
              </Button>
            ) : (
              <Button
                leftSection={<IconPlus size={14} />}
                className="mt-3"
                onClick={AdicionarProjeto}
                fullWidth
                size="xs"
                color="rgba(54, 194, 41, 1)"
              >
                Adicionar
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DespesasInicio;
