import {
  Button,
  Input,
  Modal,
  NativeSelect,
  Notification,
  NumberInput,
  Textarea,
} from "@mantine/core";
import NavBar from "../navbar/NavBar";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IconArrowBackUp, IconPlus } from "@tabler/icons-react";

function CadastrarAReceber() {
  const token = window.localStorage.getItem("x-access-token");
  const navigate = useNavigate();

  const [status, setStatus] = useState("Pendente");
  const [dataVencimento, setDataVencimento] = useState("");
  const [dataPagamento, setDataPagamento] = useState("");
  const [tipoTitulo, setTipoTitulo] = useState("");
  const [valor, setValor] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [numeroParcela, setNumeroParcela] = useState("");
  const [fornecedorCliente, setFornecedorCliente] = useState("");
  const [observacao, setObservacao] = useState("");
  const [loading, setLoading] = useState(false);

  function Cadastrar() {
    setLoading(true);
    axios({
      method: "post",
      url: "https://api-scaf.onrender.com/api/contasareceber",
      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        status,
        dataVencimento,
        dataPagamento,
        tipoTitulo,
        valor,
        formaPagamento,
        numeroParcela,
        fornecedorCliente,
        observacao,
      },
    })
      .then((res) => {
        console.log(res.data);
        setLoading(false);

        Swal.fire("Conta cadastrada !", "", "success");
        navigate("/contasareceber");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao cadastrar conta !", "", "error");
      });
  }
  function InputValor(valor) {
    valor = valor + "";
    valor = parseInt(valor.replace(/[\D]+/g, ""));
    valor = valor + "";
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    setValor(valor);
    if (valor === "NaN") setValor("");
  }

  const voltar = () => {
    navigate("/contasareceber");
  };
  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <Button
          variant="filled"
          onClick={voltar}
          color="rgba(54, 194, 41, 1)"
          leftSection={<IconArrowBackUp size={14} />}
        >
          Voltar
        </Button>

        <div className="row">
          <div className="col">
            <NativeSelect
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status:"
              data={["-------", "Pendente", "Recebido"]}
            />
          </div>
          <div className="col">
            <Input.Wrapper label="Data do Vencimento:">
              <input
                className="form-control"
                type="date"
                value={dataVencimento}
                onChange={(e) => setDataVencimento(e.target.value)}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Data do Pagamento:">
              <input
                className="form-control"
                type="date"
                name=""
                id=""
                value={dataPagamento}
                onChange={(e) => setDataPagamento(e.target.value)}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Tipo de Título:">
              <Input
                value={tipoTitulo}
                onChange={(e) => setTipoTitulo(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Valor:">
              <Input
                value={valor}
                onChange={(e) => InputValor(e.target.value)}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <NativeSelect
              value={formaPagamento}
              onChange={(e) => setFormaPagamento(e.target.value)}
              label="Forma de pagamento:"
              data={[
                "-------",
                "Dinheiro",
                "Cartão de Crédito",
                "Cartão de Débito",
                "Transferência Bancária",
                "Boleto Bancário",
                "Cheque",
                "Carnê",
                "Pagamento Digital",
                "PIX",
                "Outro",
              ]}
            />
          </div>
          <div className="col">
            <Input.Wrapper label="Nº Parcela:">
              <Input
                value={numeroParcela}
                onChange={(e) =>
                  setNumeroParcela(e.target.value)
                }
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Fornecedor/Cliente:">
              <Input
                value={fornecedorCliente}
                onChange={(e) => setFornecedorCliente(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Textarea
              label="Observação:"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
            />
          </div>
        </div>
        {loading ? (
          <Button
            leftSection={<IconPlus size={14} />}
            onClick={Cadastrar}
            className="mt-3"
            fullWidth
            loading
            size="xs"
            color="rgba(54, 194, 41, 1)"
          >
            Cadastrar
          </Button>
        ) : (
          <Button
            leftSection={<IconPlus size={14} />}
            onClick={Cadastrar}
            className="mt-3"
            fullWidth
            size="xs"
            color="rgba(54, 194, 41, 1)"
          >
            Cadastrar
          </Button>
        )}
      </div>
    </div>
  );
}

export default CadastrarAReceber;
