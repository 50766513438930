import { Button, Input, Loader, Notification } from "@mantine/core";
import NavBar from "../navbar/NavBar";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import generateRandomString from "../../utility/generateRandomString";

function UsuariosInicio() {
  const token = window.localStorage.getItem("x-access-token");
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosFiltro, setUsuariosFiltro] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  function getUsuarios() {
    axios({
      method: "get",
      url: "https://api-scaf.onrender.com/api/user",
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setUsuarios(res.data);
        setUsuariosFiltro(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.status);
        setLoading(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar usuários !", "", "error");
      });
  }

  function deletarUsuario(id) {
    setLoading(true);
    var msg = generateRandomString();
    var confim = prompt(`Digite os caracteres para excluir: ${msg} `);
    if (confim !== msg) {
      setLoading(false);
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    if (confirm("Deseja excluir o cadastro selecionado ? Essa ação não poderá ser recuperada !") === false) {
      setLoading(false);
      return;
    }
    console.log(id);
    axios({
      method: "delete",
      url: `https://api-scaf.onrender.com/api/user/${id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setLoading(false);
        getUsuarios();
        return Swal.fire(`Usuário excluido com sucesso`, "", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }
  const abrirCadastrar = () => {
    navigate("/usuarios/cadastrar");
  };
  const handleRowClick = (row) => {
    navigate(`/usuarios/atualizar/${row.id}`);
  };

  useEffect(() => {
    getUsuarios();
  }, []);

  const columns = [
    {
      name: "Nome",
      selector: (row) => row.nome,
      filter: true,
      sortable: true,
    },
    {
      name: "Usuario",
      selector: (row) => row.username,
      filter: true,
      sortable: true,
    },
    {
      name: "Perfil",
      selector: (row) => row.perfil,
      filter: true,
      sortable: true,
    },
    {
      name: "Ativo ?",
      selector: (row) => row.ativo.toString(),
      filter: true,
      sortable: true,
    },
    {
      cell: (row) => (
        <div>
          <button
            className="btn btn-danger ms-1"
            onClick={(e) => deletarUsuario(row.id)}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  async function pesquisar(value) {
    value = value.toUpperCase();
    let filteredUsers;

    try {
      filteredUsers = usuarios.filter(
        (usuarios) =>
          (usuarios.nome && usuarios.nome.toUpperCase().includes(value)) ||
          (usuarios.usuario &&
            usuarios.usuario.toUpperCase().includes(value)) ||
          (usuarios.perfil && usuarios.perfil.toUpperCase().includes(value))
      );

      setUsuariosFiltro(filteredUsers);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div>
      <NavBar />

      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <h4>Usuários ({usuariosFiltro.length})</h4>
        <div className="row my-3">
          <div className="col">
            <Button
              leftSection={<IconPlus size={14} />}
              variant="filled"
              onClick={abrirCadastrar}
              color="rgba(54, 194, 41, 1)"
            >
              Adicionar Novo
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input
              leftSection={<IconSearch size={16} />}
              onChange={(e) => pesquisar(e.target.value)}
            />
          </div>
        </div>
        {loading ? (
          <div className="text-center mt-3">
            <Loader color="green" />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={usuariosFiltro}
            onRowClicked={handleRowClick}
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
            highlightOnHover
            pointerOnHover
          />
        )}
      </div>
    </div>
  );
}

export default UsuariosInicio;
