import { Link, useNavigate } from "react-router-dom";
import ImagemLogin from "../../assets/ScafLogo.webp";

function NavBar() {
  const navigate = useNavigate();

  function Sair() {
    window.localStorage.setItem("x-access-token", "");
    navigate("/login");
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-success">
      <div className="container-fluid">
        <img
          src={ImagemLogin}
          className="img-fluid"
          alt="Logo Nexa"
          style={{ width: "2%" }}
        />

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            <Link to="/" className="nav-link">
              <i className="bi bi-house-door"></i> Inicio
            </Link>

            <Link to="/despesas" className="nav-link">
              <i className="bi bi-credit-card"></i> Projetos
            </Link>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-cash-coin"></i> Contas
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/contasapagar" className="dropdown-item">
                    <i className="bi bi-credit-card"></i> Contas a Pagar
                  </Link>
                </li>
                <li>
                  <Link to="/contasareceber" className="dropdown-item">
                    <i className="bi bi-credit-card"></i> Contas a Receber
                  </Link>
                </li>
              </ul>
            </li>
            <Link to="/estoque" className="nav-link">
              <i className="bi bi-credit-card"></i> Estoque
            </Link>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-file-earmark-text"></i> Relatórios
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/relatorios/produtos" className="dropdown-item">
                    <i className="bi bi-credit-card"></i> Estoque
                  </Link>
                </li>
                <li>
                  <Link to="/relatorios/contasapagar" className="dropdown-item">
                    <i className="bi bi-cash-coin"></i> Contas a Pagar
                  </Link>
                </li>
                <li>
                  <Link
                    to="/relatorios/contasareceber"
                    className="dropdown-item"
                  >
                    <i className="bi bi-cash-coin"></i> Contas a Receber
                  </Link>
                </li>
                <li>
                  <Link to="/relatorios/projetos" className="dropdown-item">
                    <i className="bi bi-credit-card"></i> Projetos
                  </Link>
                </li>
              </ul>
            </li>

            <Link to="/usuarios" className="nav-link">
              <i className="bi bi-person"></i> Usuários
            </Link>
            <Link to="/agenda" className="nav-link">
              <i className="bi bi-calendar-event"></i> Agenda
            </Link>
            <Link to="/banco" className="nav-link">
              <i className="bi bi-database"></i> Banco
            </Link>
          </div>
        </div>
        <div className="d-flex text-white" onClick={Sair}>
          <a className="nav-link">
            <i className="bi bi-arrow-bar-left"></i> Sair
          </a>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
