import NavBar from "../navbar/NavBar";
import {
  Button,
  Input,
  Modal,
  NativeSelect,
  Notification,
  NumberInput,
  Select,
  Textarea,
} from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IconArrowBackUp, IconPlus, IconSearch } from "@tabler/icons-react";

function CadastrarEstoque() {
  const token = window.localStorage.getItem("x-access-token");
  const navigate = useNavigate();

  const [projetos, setProjetos] = useState([]);
  const [projeto, setProjeto] = useState("");
  const [nome, setNome] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [status, setStatus] = useState("Disponível");
  const [precoCompra, setPrecoCompra] = useState("");
  const [responsavel, setResponsavel] = useState("");
  const [local, setLocal] = useState("");
  const [observacao, setObservacao] = useState("");
  const [loading, setLoading] = useState(false);

  function Cadastrar() {
    if (!nome) {
      return Swal.fire(
        "Um nome deve ser informado !",
        "",
        "error"
      );
    }
    setLoading(true);
    let idProjeto;

    if (projeto) {
      idProjeto = projetos.find((value) => value.descricao === projeto).id;
    }
    axios({
      method: "post",
      url: "https://api-scaf.onrender.com/api/produto",
      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        status,
        qtd: quantidade,
        nome,
        precoCompra,
        local,
        responsavel,
        observacao,
        dataAtualizacao: new Date(),
        ProjetoId: idProjeto,
      },
    })
      .then((res) => {
        console.log(res.data);
        setLoading(false);

        Swal.fire("Produto cadastrado !", "", "success");
        navigate("/estoque");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao cadastrar produto !", "", "error");
      });
  }
  function InputPrecoCompra(valor) {
    valor = valor + "";
    valor = parseInt(valor.replace(/[\D]+/g, ""));
    valor = valor + "";
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    setPrecoCompra(valor);
    if (valor == "NaN") setPrecoCompra("");
  }

  function getProjetos() {
    axios({
      method: "get",
      url: "https://api-scaf.onrender.com/api/projetos",
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setProjetos(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar projetos !", "", "error");
      });
  }

  const voltar = () => {
    navigate("/estoque");
  };

  useEffect(() => {
    getProjetos();
  }, []);

  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <Button
          variant="filled"
          onClick={voltar}
          color="rgba(54, 194, 41, 1)"
          leftSection={<IconArrowBackUp size={14} />}
        >
          Voltar
        </Button>

        <div className="row mt-3">
          <div className="col-3">
            <NativeSelect
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status"
              data={["Disponível", "Em uso"]}
            />
          </div>
          <div className="col">
            <Input.Wrapper label="Nome:" withAsterisk>
              <Input onChange={(e) => setNome(e.target.value)} value={nome} />
            </Input.Wrapper>
          </div>
          <div className="col-2">
            <Input.Wrapper label="Qtd:">
              <Input
                onChange={(e) =>
                  setQuantidade(e.target.value.replace(/\D/g, ""))
                }
                value={quantidade}
              />
            </Input.Wrapper>
          </div>
          <div className="col-2">
            <Input.Wrapper label="Preço de compra:">
              <Input
                onChange={(e) => InputPrecoCompra(e.target.value)}
                value={precoCompra}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Select
              value={projeto}
              label="Projeto"
              placeholder="Escolha um projeto"
              data={projetos
                .sort((a, b) => a.descricao.localeCompare(b.descricao))
                .map((projeto) => projeto.descricao)}
              onChange={setProjeto}
              searchable
              nothingFoundMessage="Não encontrado ..."
            />
          </div>
          <div className="col">
            <Input.Wrapper label="Responsável:">
              <Input
                onChange={(e) => setResponsavel(e.target.value)}
                value={responsavel}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Local:">
              <Input onChange={(e) => setLocal(e.target.value)} value={local} />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Textarea
              label="Observação:"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
            />
          </div>
        </div>
        {loading ? (
          <Button
            leftSection={<IconPlus size={14} />}
            onClick={Cadastrar}
            className="mt-3"
            fullWidth
            loading
            size="xs"
            color="rgba(54, 194, 41, 1)"
          >
            Cadastrar
          </Button>
        ) : (
          <Button
            leftSection={<IconPlus size={14} />}
            onClick={Cadastrar}
            className="mt-3"
            fullWidth
            size="xs"
            color="rgba(54, 194, 41, 1)"
          >
            Cadastrar
          </Button>
        )}
      </div>
    </div>
  );
}

export default CadastrarEstoque;
