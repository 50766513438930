import { useEffect, useState } from "react";
import NavBar from "../navbar/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Button, NativeSelect, Switch } from "@mantine/core";
import { IconFileAnalytics } from "@tabler/icons-react";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function RelatorioProdutos() {
  const navigate = useNavigate();
  const token = window.localStorage.getItem("x-access-token");
  const params = useParams();
  const [produtos, setProdutos] = useState([]);
  const [estoqueMaiorQue0, setEstoqueMaiorQue0] = useState(false);
  const [ordenarMaiorQtd, setOrdenarMaiorQtd] = useState(false);
  const [ordemAlfabetica, setOrdemAlfabetica] = useState(false);
  const [status, setStatus] = useState("Todos");
  const [loading, setLoading] = useState(true);

  function getProdutos() {
    axios({
      method: "get",
      url: "https://api-scaf.onrender.com/api/produto",
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setProdutos(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar produtos !", "", "error");
      });
  }

  useEffect(() => {
    getProdutos();
  }, []);

  function Imprimir() {
    var produtosFiltro = produtos;

    if (estoqueMaiorQue0) {
      produtosFiltro = produtosFiltro.filter((el) => el.qtd > 0);
    }
    if (ordenarMaiorQtd) {
      produtosFiltro = produtosFiltro.sort(function (a, b) {
        return b.qtd - a.qtd;
      });
    }
    if (ordemAlfabetica) {
      produtosFiltro = produtosFiltro.sort((a, b) =>
        a.nome.localeCompare(b.nome)
      );
    }
    if (status === "Disponível") {
      produtosFiltro = produtosFiltro.filter(
        (item) => item.status === "Disponível"
      );
    }
    if (status === "Em uso") {
      produtosFiltro = produtosFiltro.filter(
        (item) => item.status === "Em uso"
      );
    }

    var docDefinition = {
      content: [
        {
          text: `${new Date().toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
          })}`,
          fontSize: 10,
        },
        {
          text: `Relatório de Estoque`,
          style: { bold: true, alignment: "center" },
          fontSize: 18,
        },
        {
          table: {
            headerRows: 1,
            widths: [
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
            ], // Ajuste as larguras conforme necessário
            body: [
              [
                "ID",
                "Data Atualização",
                "Nome",
                "Preço de Compra",
                "Qtd",
                "Status",
                "Responsável",
                "Local",
              ], // Cabeçalho da tabela
              ...produtosFiltro.map((item) => [
                item.id,
                new Date(item.dataAtualizacao).toLocaleString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour12: false,
                }),
                item.nome,
                item.precoCompra,
                item.qtd,
                item.status,
                item.responsavel,
                item.local,
              ]),
            ],
            layout: "auto",
          },
        },
      ],
      pageMargins: [20, 20, 20, 20], // Configurar margens menores
    };

    pdfMake.createPdf(docDefinition).open();
  }
  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <h4>Estoque</h4>
        <div className="row mt-3">
          <div className="col">
            <Switch
              color="rgba(54, 194, 41, 1)"
              checked={estoqueMaiorQue0}
              label="Estoque maior que 0"
              onChange={(e) => setEstoqueMaiorQue0(e.currentTarget.checked)}
            />
          </div>
          <div className="col">
            <Switch
              color="rgba(54, 194, 41, 1)"
              checked={ordenarMaiorQtd}
              label="Ordenar pela maior quantidade"
              onChange={(e) => setOrdenarMaiorQtd(e.currentTarget.checked)}
            />
          </div>
          <div className="col">
            <Switch
              color="rgba(54, 194, 41, 1)"
              checked={ordemAlfabetica}
              label="Odem alfabética"
              onChange={(e) => setOrdemAlfabetica(e.currentTarget.checked)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <NativeSelect
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status:"
              data={["Todos", "Disponível", "Em uso"]}
            />
          </div>
        </div>
        {loading ? (
          <Button
            leftSection={<IconFileAnalytics size={14} />}
            className="mt-3"
            fullWidth
            loading
            size="xs"
            onClick={Imprimir}
            color="rgba(54, 194, 41, 1)"
          >
            imprimir
          </Button>
        ) : (
          <Button
            leftSection={<IconFileAnalytics size={14} />}
            className="mt-3"
            fullWidth
            size="xs"
            onClick={Imprimir}
            color="rgba(54, 194, 41, 1)"
          >
            imprimir
          </Button>
        )}
      </div>
    </div>
  );
}

export default RelatorioProdutos;
