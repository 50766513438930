import { useEffect, useState } from "react";
import NavBar from "../navbar/NavBar";
import {
  Button,
  Input,
  Loader,
  NativeSelect,
  PasswordInput,
  Switch,
  Tooltip,
} from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { IconArrowBackUp, IconPencilCheck } from "@tabler/icons-react";

function AtualizarUsuario() {
  const navigate = useNavigate();
  const token = window.localStorage.getItem("x-access-token");
  const params = useParams();

  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [perfil, setPerfil] = useState("Operador");
  const [ativo, setAtivo] = useState(false);
  const [alterarSenha, setAlterarSenha] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAtualizar, setLoadingAtualizar] = useState(false);

  const [focused, setFocused] = useState(false);

  function Atualizar() {
    if (senha && senha.length < 8) {
      return Swal.fire(
        "A senha deve conter no mínimo 8 caracteres !",
        "",
        "error"
      );
    }
    if (!usuario) {
      return Swal.fire(
        "Um nome de usuário deve ser informado !",
        "",
        "error"
      );
    }
    setLoadingAtualizar(true);
    axios({
      method: "put",
      url: `https://api-scaf.onrender.com/api/user/${id}`,
      data: {
        id: id,
        nome: nome,
        Username: usuario,
        Password: senha,
        Perfil: perfil,
        Ativo: ativo,
        AlterarSenha: alterarSenha,
      },
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setNome("");
        setUsuario("");
        setSenha("");
        setPerfil("");
        setAtivo("");
        setAlterarSenha("");
        navigate(`/usuarios`);
        setLoadingAtualizar(false);
        return Swal.fire("Usuário atualizado com sucesso !", "", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoadingAtualizar(false);
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }
  useEffect(() => {
    axios({
      method: "get",
      url: `https://api-scaf.onrender.com/api/user/${params.id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setId(res.data.id);
        setNome(res.data.nome);
        setUsuario(res.data.username);
        setPerfil(res.data.perfil);
        setAtivo(res.data.ativo);
        setAlterarSenha(res.data.alterarSenha);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar usuário !", "", "error");
      });
  }, []);
  const voltar = () => {
    navigate("/usuarios");
  };
  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <Button
          variant="filled"
          onClick={voltar}
          color="rgba(54, 194, 41, 1)"
          leftSection={<IconArrowBackUp size={14} />}
        >
          Voltar
        </Button>
        {loading ? (
          <div className="text-center mt-3">
            <Loader color="green" />
          </div>
        ) : (
          <div className="row">
            <div className="col">
              <Input.Wrapper label="Nome:">
                <Input onChange={(e) => setNome(e.target.value)} value={nome} />
              </Input.Wrapper>
            </div>
            <div className="col">
              <Input.Wrapper label="Usuário:" withAsterisk>
                <Input
                  onChange={(e) => setUsuario(e.target.value)}
                  value={usuario}
                />
              </Input.Wrapper>
            </div>
            <div className="row">
              <div className="col">
                <PasswordInput
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  label="Senha:"
                  inputContainer={(children) => (
                    <Tooltip
                      label="A senha deve possuir no mínimo 8 caracteres"
                      position="top-start"
                      opened={focused}
                    >
                      {children}
                    </Tooltip>
                  )}
                />
              </div>
              <div className="col">
                <NativeSelect
                  value={perfil}
                  onChange={(e) => setPerfil(e.target.value)}
                  label="Perfil"
                  data={["Operador","Administrador"]}
                />
              </div>
              <div className="col mt-3">
                <Switch
                  color="rgba(54, 194, 41, 1)"
                  checked={ativo}
                  label="Ativo ?"
                  onChange={(e) => setAtivo(e.currentTarget.checked)}
                />
                <Switch
                  color="rgba(54, 194, 41, 1)"
                  checked={alterarSenha}
                  className="mt-1"
                  label="Alterar Senha ?"
                  onChange={(e) => setAlterarSenha(e.currentTarget.checked)}
                />
              </div>
            </div>
            {loadingAtualizar ? (
              <Button
                leftSection={<IconPencilCheck size={14} />}
                className="mt-3"
                loading
                fullWidth
                size="xs"
                onClick={Atualizar}
                color="rgba(54, 194, 41, 1)"
              >
                Atualizar
              </Button>
            ) : (
              <Button
                leftSection={<IconPencilCheck size={14} />}
                className="mt-3"
                fullWidth
                size="xs"
                onClick={Atualizar}
                color="rgba(54, 194, 41, 1)"
              >
                Atualizar
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AtualizarUsuario;
