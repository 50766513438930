import { Route, Routes } from "react-router-dom";
import Login from "../pages/session/Login";
import Inicio from "../pages/inicio/Inicio";
import UsuariosInicio from "../pages/usuarios/UsuariosInicio";
import Cadastrar from "../pages/usuarios/Cadastrar";
import AtualizarUsuario from "../pages/usuarios/AtualizarUsuario";
import AlterarSenha from "../pages/session/AlterarSenha";
import Private from "../pages/private/Private";
import DespesasInicio from "../pages/despesas/DespesasInicio";
import ExibirDespesas from "../pages/despesas/ExibirDespesas";
import InicioAPagar from "../pages/contas/InicioAPagar";
import CadastrarAPagar from "../pages/contas/CadastrarAPagar";
import AtualizarAPagar from "../pages/contas/AtualizarAPagar";
import InicioAReceber from "../pages/contas/InicioAReceber";
import CadastrarAReceber from "../pages/contas/CadastrarAReceber";
import AtualizarAReceber from "../pages/contas/AtualizarAReceber";
import InicioEstoque from "../pages/estoque/InicioEstoque";
import CadastrarEstoque from "../pages/estoque/CadastrarEstoque";
import AtualizarEstoque from "../pages/estoque/AtualizarEstoque";
import RelatorioProdutos from "../pages/relatorios/RelatorioProdutos";
import RelatorioContasAPagar from "../pages/relatorios/RelatorioContasAPagar";
import RelatorioContasAReceber from "../pages/relatorios/RelatorioContasAReceber";
import RelatorioProjeto from "../pages/relatorios/RelatorioProjeto";
import Banco from "../pages/banco/Banco";
import Agenda from "../pages/agenda/Agenda";

function RoutesApp() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/alterarsenha"
        element={
          <Private>
            <AlterarSenha />
          </Private>
        }
      />
      <Route
        path="/"
        element={
          <Private>
            <Inicio />
          </Private>
        }
      />
      <Route
        path="/usuarios"
        element={
          <Private>
            <UsuariosInicio />
          </Private>
        }
      />
      <Route
        path="/usuarios/cadastrar"
        element={
          <Private>
            <Cadastrar />
          </Private>
        }
      />
      <Route
        path="/usuarios/atualizar/:id"
        element={
          <Private>
            <AtualizarUsuario />
          </Private>
        }
      />
      <Route
        path="/despesas"
        element={
          <Private>
            <DespesasInicio />
          </Private>
        }
      />
      <Route
        path="/despesas/:id"
        element={
          <Private>
            <ExibirDespesas />
          </Private>
        }
      />
      <Route
        path="/contasapagar"
        element={
          <Private>
            <InicioAPagar />
          </Private>
        }
      />
      <Route
        path="/contasapagar/cadastrar"
        element={
          <Private>
            <CadastrarAPagar />
          </Private>
        }
      />
      <Route
        path="/contasapagar/atualizar/:id"
        element={
          <Private>
            <AtualizarAPagar />
          </Private>
        }
      />
      <Route
        path="/contasareceber"
        element={
          <Private>
            <InicioAReceber />
          </Private>
        }
      />
      <Route
        path="/contasareceber/cadastrar"
        element={
          <Private>
            <CadastrarAReceber />
          </Private>
        }
      />
      <Route
        path="/contasareceber/atualizar/:id"
        element={
          <Private>
            <AtualizarAReceber />
          </Private>
        }
      />
      <Route
        path="/estoque"
        element={
          <Private>
            <InicioEstoque />
          </Private>
        }
      />
      <Route
        path="/estoque/cadastrar"
        element={
          <Private>
            <CadastrarEstoque />
          </Private>
        }
      />
      <Route
        path="/estoque/atualizar/:id"
        element={
          <Private>
            <AtualizarEstoque />
          </Private>
        }
      />

      <Route
        path="/relatorios/produtos"
        element={
          <Private>
            <RelatorioProdutos />
          </Private>
        }
      />
      <Route
        path="/relatorios/contasapagar"
        element={
          <Private>
            <RelatorioContasAPagar />
          </Private>
        }
      />
      <Route
        path="/relatorios/contasareceber"
        element={
          <Private>
            <RelatorioContasAReceber />
          </Private>
        }
      />
       <Route
        path="/relatorios/projetos"
        element={
          <Private>
            <RelatorioProjeto />
          </Private>
        }
      />
      <Route
        path="/banco"
        element={
          <Private>
            <Banco />
          </Private>
        }
      />
      <Route
        path="/agenda"
        element={
          <Private>
            <Agenda />
          </Private>
        }
      />
    </Routes>
  );
}

export default RoutesApp;
