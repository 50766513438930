import { Button, Input, Loader, NativeSelect } from "@mantine/core";
import NavBar from "../navbar/NavBar";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import "moment-timezone";
import moment from "moment-timezone";
moment.locale("pt-br");

function InicioAReceber() {
  const token = window.localStorage.getItem("x-access-token");
  const navigate = useNavigate();
  const [contas, setContas] = useState([]);
  const [contasFiltro, setContasFiltro] = useState([]);
  const [loading, setLoading] = useState(true);

  const [status, setStatus] = useState("Pendente");

  const columns = [
    {
      name: "Status",
      selector: (row) => row.status,
      filter: true,
      sortable: true,
    },
    {
      name: "Data do Vencimento",
      selector: (row) => moment.utc(row.dataVencimento).format("DD/MM/YYYY"),

      filter: true,
      sortable: true,
    },
    {
      name: "Data do Pagamento",
      selector: (row) => moment.utc(row.dataPagamento).format("DD/MM/YYYY"),

      filter: true,
      sortable: true,
    },

    {
      name: "Tipo de Titulo",
      selector: (row) => row.tipoTitulo,
      filter: true,
      sortable: true,
    },

    {
      name: "Valor",
      selector: (row) => row.valor,
      filter: true,
      sortable: true,
    },
    {
      name: "Fornecedor/Cliente",
      selector: (row) => row.fornecedorCliente,
      filter: true,
      sortable: true,
    },
    {
      name: "Nº Parcela",
      selector: (row) => row.numeroParcela,
      filter: true,
      sortable: true,
    },
    {
      cell: (row) => (
        <div>
          <button
            className="btn btn-danger ms-1"
            onClick={(e) => DeletarConta(row.id)}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handleRowClick = (row) => {
    navigate(`/contasareceber/atualizar/${row.id}`);
  };
  function getContas() {
    axios({
      method: "get",
      url: `https://api-scaf.onrender.com/api/contasareceber/status/${status}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setContas(res.data);
        setContasFiltro(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar usuários !", "", "error");
      });
  }
  useEffect(() => {
    getContas();
  }, []);
  useEffect(() => {
    getContas();
  }, [status]);
  function DeletarConta(id) {
    setLoading(true);
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Deseja excluir o cadastro selecionado ?") === false) {
      setLoading(false);
      return;
    }
    console.log(id);
    axios({
      method: "delete",
      url: `https://api-scaf.onrender.com/api/contasareceber/${id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        getContas();
        setStatus(false);

        return Swal.fire(`Conta excluida com sucesso`, "", "success");
      })
      .catch((err) => {
        console.log(err);
        setStatus(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }
  const abrirCadastrar = () => {
    navigate("/contasareceber/cadastrar");
  };
  async function pesquisar(value) {
    value = value.toUpperCase();
    let filteredContas;
    try {
      filteredContas = contas.filter(
        (contas) =>
          (contas.status && contas.status.toUpperCase().includes(value)) ||
          (contas.dataVencimento &&
            new Date(contas.dataVencimento)
              .toLocaleString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              })
              .includes(value)) ||
          (contas.dataPagamento &&
            new Date(contas.dataPagamento)
              .toLocaleString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              })
              .includes(value)) ||
          (contas.tipoTitulo &&
            contas.tipoTitulo.toUpperCase().includes(value)) ||
          (contas.valor && contas.valor.toUpperCase().includes(value)) ||
          (contas.fornecedorCliente &&
            contas.fornecedorCliente.toUpperCase().includes(value)) ||
          (contas.numeroParcela &&
            contas.numeroParcela.toUpperCase().includes(value))
      );

      setContasFiltro(filteredContas);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <h4>Contas a Receber ({contas.length})</h4>

        <div className="row my-3">
          <div className="col">
            <Button
              onClick={abrirCadastrar}
              leftSection={<IconPlus size={14} />}
              variant="filled"
              color="rgba(54, 194, 41, 1)"
            >
              Adicionar Novo
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <NativeSelect
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status:"
              data={["Todos", "Pendente", "Recebido"]}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <Input
              leftSection={<IconSearch size={16} />}
              onChange={(e) => pesquisar(e.target.value)}
            />
          </div>
        </div>
        {loading ? (
          <div className="text-center mt-3">
            <Loader color="green" />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={contasFiltro}
            onRowClicked={handleRowClick}
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
            highlightOnHover
            pointerOnHover
          />
        )}
      </div>
    </div>
  );
}

export default InicioAReceber;
