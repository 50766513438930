import {
  Button,
  Input,
  Modal,
  NativeSelect,
  Notification,
  NumberInput,
  Select,
  Textarea,
} from "@mantine/core";
import NavBar from "../navbar/NavBar";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IconArrowBackUp, IconPlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

function CadastrarAPagar() {
  const token = window.localStorage.getItem("x-access-token");
  const navigate = useNavigate();

  const [status, setStatus] = useState("Pendente");
  const [dataVencimento, setDataVencimento] = useState("");
  const [dataPagamento, setDataPagamento] = useState("");
  const [tipoTitulo, setTipoTitulo] = useState("");
  const [valor, setValor] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [numeroParcela, setNumeroParcela] = useState("");
  const [fornecedorCliente, setFornecedorCliente] = useState("");
  const [classificacaoDespeza, setClassificacaoDespeza] =
    useState("Despesa fixa");
  const [nf, setNf] = useState("");
  const [dataDespeza, setDataDespeza] = useState("");
  const [descricaoDespeza, setDescricaoDespeza] = useState("");
  const [observaocaoDespeza, setObservaocaoDespeza] = useState("");
  const [projetos, setProjetos] = useState([]);
  const [projeto, setProjeto] = useState("");
  const [loading, setLoading] = useState(false);

  function getProjetos() {
    axios({
      method: "get",
      url: "https://api-scaf.onrender.com/api/projetos",
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setProjetos(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar projetos !", "", "error");
      });
  }
  const voltar = () => {
    navigate("/contasapagar");
  };
  const handleInputValor = (event) => {
    var valor = event.target.value;

    valor = valor + "";
    valor = parseInt(valor.replace(/[\D]+/g, ""));
    valor = valor + "";
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    setValor(valor);
    if (valor == "NaN") setValor("");
  };

  useEffect(() => {
    getProjetos();
  }, []);
  function AdicionarDespeza() {

    var classificacaoDespezaPadrao = classificacaoDespeza;
    if (!dataDespeza) {
      return Swal.fire("Uma data deve ser informada !", "", "error");
    }
    if (!descricaoDespeza) {
      return Swal.fire("Uma descrição deve ser informada !", "", "error");
    }
    if (!classificacaoDespeza || classificacaoDespeza === "") {
      classificacaoDespezaPadrao = "Despesa fixa";
    }
    setLoading(true)
    let idProjeto;

    if (projeto) {
      idProjeto = projetos.find((value) => value.descricao === projeto);
      if (idProjeto) {
        idProjeto = idProjeto.id;
      }
    }
    axios({
      method: "post",
      url: `https://api-scaf.onrender.com/api/despesas`,

      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        data: dataDespeza,
        classificacao: classificacaoDespezaPadrao,
        descricao: descricaoDespeza,
        valor,
        nf,
        observacao: observaocaoDespeza,
        status,
        dataVencimento,
        dataPagamento,
        tipoTitulo,
        formaPagamento,
        numeroParcela,
        fornecedorCliente,
        ProjetoId: idProjeto,
      },
    })
      .then((res) => {
        getProjetos();
        setLoading(false)
        Swal.fire("Despesa adicionada com sucesso !", "", "success");
        navigate("/contasapagar");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }
  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <Button
          variant="filled"
          onClick={voltar}
          color="rgba(54, 194, 41, 1)"
          leftSection={<IconArrowBackUp size={14} />}
        >
          Voltar
        </Button>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Data:" withAsterisk>
              <input
                className="form-control"
                type="date"
                name=""
                id=""
                value={dataDespeza}
                onChange={(e) => setDataDespeza(e.target.value)}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Descrição:" withAsterisk>
              <Input
                value={descricaoDespeza}
                onChange={(e) => setDescricaoDespeza(e.target.value)}
              />
            </Input.Wrapper>
          </div>
          <div className="col-3">
            <NativeSelect
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status:"
              data={["-------", "Pendente", "Pago"]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Select
              value={projeto}
              label="Projeto"
              placeholder="Escolha um projeto"
              data={projetos
                .sort((a, b) => a.descricao.localeCompare(b.descricao))
                .map((projeto) => projeto.descricao)}
              onChange={setProjeto}
              searchable
              nothingFoundMessage="Não encontrado ..."
            />
          </div>
          <div className="col">
            <Input.Wrapper label="Data do Vencimento:">
              <input
                className="form-control"
                type="date"
                value={dataVencimento}
                onChange={(e) => setDataVencimento(e.target.value)}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Data do Pagamento:">
              <input
                className="form-control"
                type="date"
                name=""
                id=""
                value={dataPagamento}
                onChange={(e) => setDataPagamento(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Input.Wrapper label="Tipo de Título:">
              <Input
                value={tipoTitulo}
                onChange={(e) => setTipoTitulo(e.target.value)}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <NativeSelect
              value={formaPagamento}
              onChange={(e) => setFormaPagamento(e.target.value)}
              label="Forma de pagamento:"
              data={[
                "-------",
                "Dinheiro",
                "Cartão de Crédito",
                "Cartão de Débito",
                "Transferência Bancária",
                "Boleto Bancário",
                "Cheque",
                "Carnê",
                "Pagamento Digital",
                "PIX",
                "Outro",
              ]}
            />
          </div>
          <div className="col-3">
            <Input.Wrapper label="Nº Parcela:">
              <Input
                value={numeroParcela}
                onChange={(e) =>
                  setNumeroParcela(e.target.value)
                }
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Fornecedor/Cliente:">
              <Input
                value={fornecedorCliente}
                onChange={(e) => setFornecedorCliente(e.target.value)}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <NativeSelect
              label="Classificação da Despesa:"
              data={["Despesa fixa", "Despesa corrente"]}
              value={classificacaoDespeza}
              onChange={(e) => setClassificacaoDespeza(e.target.value)}
            />
          </div>
          <div className="col">
            <Input.Wrapper label="Valor:">
              <Input onChange={(e) => handleInputValor(e)} value={valor} />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="NF:">
              <Input
                value={nf}
                onChange={(e) => setNf(e.target.value.replace(/[^0-9.]/g, ""))}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Textarea
              label="Observação:"
              value={observaocaoDespeza}
              onChange={(e) => setObservaocaoDespeza(e.target.value)}
            />
          </div>
        </div>
        <div className="row mt-3">
          {loading ? (
            <Button
              fullWidth
              size="xs"
              onClick={AdicionarDespeza}
              leftSection={<IconPlus size={14} />}
              variant="filled"
              color="rgba(54, 194, 41, 1)"
              loading
            >
              Adicionar
            </Button>
          ) : (
            <Button
              fullWidth
              size="xs"
              onClick={AdicionarDespeza}
              leftSection={<IconPlus size={14} />}
              variant="filled"
              color="rgba(54, 194, 41, 1)"
            >
              Adicionar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CadastrarAPagar;
