import { useEffect, useState } from "react";
import NavBar from "../navbar/NavBar";
import {
  Button,
  Input,
  Loader,
  Modal,
  NativeSelect,
  NumberInput,
  PasswordInput,
  Switch,
  Tabs,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
  IconArrowBackUp,
  IconFile,
  IconPencil,
  IconPencilCheck,
  IconPencilX,
  IconPhoto,
  IconPlus,
  IconSearch,
} from "@tabler/icons-react";
import DataTable from "react-data-table-component";
import { useDisclosure } from "@mantine/hooks";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment/moment";
import "moment-timezone";
import Imagens from "./Imagens";
moment.locale("pt-br");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function ExibirDespesas() {
  const navigate = useNavigate();
  const token = window.localStorage.getItem("x-access-token");
  const params = useParams();

  const [opened, { open, close }] = useDisclosure(false);
  const [openEditDados, setOpenEditDados] = useState(false);
  const [openEditDespesas, setOpenEditDespesas] = useState(false);

  const [id, setId] = useState(params.id);
  const [projeto, setProjeto] = useState();
  const [valor, setValor] = useState("");
  const [totalAcumulado, setTotalAcumulado] = useState("");
  const [descricao, setDescricao] = useState("");
  const [data, setData] = useState("");
  const [observacao, setObservacao] = useState("");
  const [despezas, setDespezas] = useState([]);
  const [despezasFiltro, setDespezasFiltro] = useState([]);
  const [status, setStatus] = useState("Pendente");
  const [dataVencimento, setDataVencimento] = useState("");
  const [dataPagamento, setDataPagamento] = useState("");
  const [tipoTitulo, setTipoTitulo] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [numeroParcela, setNumeroParcela] = useState("");
  const [fornecedorCliente, setFornecedorCliente] = useState("");

  const [idDespeza, setIdDespeza] = useState("");
  const [dataDespeza, setDataDespeza] = useState("");
  const [descricaoDespeza, setDescricaoDespeza] = useState("");
  const [classificacaoDespeza, setClassificacaoDespeza] =
    useState("Despesa fixa");
  const [nf, setNf] = useState("");
  const [observaocaoDespeza, setObservaocaoDespeza] = useState("");

  const [totalDespezas, setTotalDespezas] = useState("");

  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [numero, setNumero] = useState(0);
  const [uf, setUf] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingAdicionar, setLoadingAdicionar] = useState(false);
  const [loadingExcluir, setLoadingExcluir] = useState(false);

  function Atualizar() {
    setLoadingAdicionar(true);
    axios({
      method: "put",
      url: `https://api-scaf.onrender.com/api/projetos`,

      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        id,
        descricao,
        observacao,
        data,
        cep,
        logradouro,
        bairro,
        municipio,
        numero,
        uf,
      },
    })
      .then((res) => {
        setLoadingAdicionar(false);
        return Swal.fire("Projeto atualizado com sucesso !", "", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoadingAdicionar(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }
  function AtualizarDespeza() {
    setLoadingAdicionar(true);
    if (!dataDespeza) {
      return Swal.fire("Uma data deve ser informada !", "", "error");
    }
    if (!descricaoDespeza) {
      return Swal.fire("Uma descrição deve ser informada !", "", "error");
    }

    axios({
      method: "put",
      url: `https://api-scaf.onrender.com/api/despesas`,

      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        id: idDespeza,
        data: dataDespeza,
        classificacao: classificacaoDespeza,
        descricao: descricaoDespeza,
        valor: valor,
        totalAcumulado: totalAcumulado,
        nf: nf,
        projetoId: params.id,
        observacao: observaocaoDespeza,
        status,
        dataVencimento,
        dataPagamento,
        tipoTitulo,
        formaPagamento,
        numeroParcela,
        fornecedorCliente,
      },
    })
      .then((res) => {
        GetProjetos();
        setLoadingAdicionar(false);
        return Swal.fire("Despesa atualizado com sucesso !", "", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoadingAdicionar(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }
  function AdicionarDespeza() {
    var classificacaoDespezaPadrao = classificacaoDespeza;
    if (!dataDespeza) {
      return Swal.fire("Uma data deve ser informada !", "", "error");
    }
    if (!descricaoDespeza) {
      return Swal.fire("Uma descrição deve ser informada !", "", "error");
    }
    setLoadingAdicionar(true);
    if (!classificacaoDespeza || classificacaoDespeza === "") {
      classificacaoDespezaPadrao = "Despesa fixa";
    }
    axios({
      method: "post",
      url: `https://api-scaf.onrender.com/api/despesas`,

      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        data: dataDespeza,
        classificacao: classificacaoDespezaPadrao,
        descricao: descricaoDespeza,
        valor,
        totalAcumulado,
        nf,
        projetoId: params.id,
        observacao: observaocaoDespeza,
        status,
        dataVencimento,
        dataPagamento,
        tipoTitulo,
        formaPagamento,
        numeroParcela,
        fornecedorCliente,
      },
    })
      .then((res) => {
        GetProjetos();
        setLoadingAdicionar(false);
        return Swal.fire("Despesa adicionada com sucesso !", "", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoadingAdicionar(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }
  const columns = [
    {
      name: "Data",
      selector: (row) =>
        new Date(row.data).toLocaleString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour12: false,
        }),
      filter: true,
      sortable: true,
      sortFunction: (a, b) => new Date(a.data) - new Date(b.data),
    },
    {
      name: "Descrição",
      selector: (row) => row.descricao,
      filter: true,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      filter: true,
      sortable: true,
    },
    {
      name: "Valor",
      selector: (row) => row.valor,
      filter: true,
      sortable: true,
    },

    {
      name: "NF",
      selector: (row) => row.nf,
      filter: true,
      sortable: true,
    },
    {
      cell: (row) => (
        <div>
          <button
            className="btn btn-danger ms-1"
            onClick={(e) => DeletarDespesa(row)}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  function DeletarDespesa(row) {
    setLoadingExcluir(true);
    const id = row.id;
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Deseja excluir o cadastro selecionado ?") === false) {
      setLoadingExcluir(false);
      return;
    }
    axios({
      method: "delete",
      url: `https://api-scaf.onrender.com/api/despesas/${id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        GetProjetos();
        setLoadingExcluir(false);

        return Swal.fire(`Despesa excluida com sucesso`, "", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoadingExcluir(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }
  function GetProjetos() {
    axios({
      method: "get",
      url: `https://api-scaf.onrender.com/api/projetos/${params.id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);

        setProjeto(res.data);
        setData(res.data.data);
        setDescricao(res.data.descricao);
        setObservacao(res.data.observacao);
        setDespezas(
          res.data.despezas
            .sort((a, b) => {
              if (new Date(a.data) < new Date(b.data)) return -1;
              if (new Date(a.data) > new Date(b.data)) return 1;
              return 0;
            })
            .reverse()
        );
        setDespezasFiltro(res.data.despezas);
        setCep(res.data.cep);
        setLogradouro(res.data.logradouro);
        setBairro(res.data.bairro);
        setMunicipio(res.data.municipio);
        setNumero(res.data.numero);
        setUf(res.data.uf);
        CalculaTotalDespezas(res.data.despezas);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar projetos !", "", "error");
      });
  }
  useEffect(() => {
    GetProjetos();
  }, []);
  const voltar = () => {
    navigate("/despesas");
  };
  const handleRowClick = (row) => {
    const id = row.id;
    var despeza = despezas.find((x) => x.id === id);

    setIdDespeza(despeza.id);
    setDataDespeza(new Date(despeza.data).toISOString().split("T")[0]);
    setDescricaoDespeza(despeza.descricao);
    setClassificacaoDespeza(despeza.classificacao);
    setValor(despeza.valor);
    setTotalAcumulado(despeza.totalAcumulado);
    setNf(despeza.nf);
    setObservaocaoDespeza(despeza.observacao);
    setStatus(despeza.status);
    setDataVencimento(despeza.dataVencimento);
    setDataPagamento(despeza.dataPagamento);
    setTipoTitulo(despeza.tipoTitulo);
    setFormaPagamento(despeza.formaPagamento);
    setNumeroParcela(despeza.numeroParcela);
    setFornecedorCliente(despeza.fornecedorCliente);
    setOpenEditDespesas(true);
    open(true);
  };

  const handleCloseModal = () => {
    setOpenEditDespesas(false);
    close(true);
  };
  const handleInputValor = (event) => {
    var valor = event.target.value;

    valor = valor + "";
    valor = parseInt(valor.replace(/[\D]+/g, ""));
    valor = valor + "";
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    setValor(valor);
    if (valor == "NaN") setValor("");
  };

  function FormataDinheiro(valor) {
    if (typeof valor === "string") {
      // Converte a string para um número de ponto flutuante
      valor = parseFloat(valor.replace(/\./g, "").replace(",", "."));
    } else {
      // Se não for uma string, assume que já é um número
      valor = parseFloat(valor);
    }

    if (!isNaN(valor)) {
      // Se o valor for um número válido
      setTotalDespezas(
        valor.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
      );
    } else {
      setTotalDespezas("");
    }
  }
  useEffect(() => {
    if (opened === false) {
      setDataDespeza("");
      setDescricaoDespeza("");
      setClassificacaoDespeza("");
      setValor("");
      setTotalAcumulado("");
      setNf("");
      setObservaocaoDespeza("");
      setObservaocaoDespeza("");
      setStatus("");
      setDataVencimento("");
      setDataPagamento("");
      setTipoTitulo("");
      setFormaPagamento("");
      setNumeroParcela("");
      setFornecedorCliente("");
    }
  }, [opened]);

  function CalculaTotalDespezas(despeza) {
    var total = 0;
    if (despeza) {
      for (let index = 0; index < despeza.length; index++) {
        if (despeza[index].valor) {
          total += parseFloat(
            despeza[index].valor.replace(/\./g, "").replace(",", ".")
          );
        }
      }
      return FormataDinheiro(total);
    }
  }
  function ImprimirDespezas() {
    var docDefinition = {
      content: [
        {
          text: `${new Date().toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
          })}`,
          fontSize: 10,
        },
        {
          text: `${descricao}`,
          style: { bold: true, alignment: "center" },
          fontSize: 18,
        },
        {
          text: `Total: R$${totalDespezas}`,
          style: { alignment: "right" },
          fontSize: 12,
        },
        {
          table: {
            headerRows: 1,
            widths: ["auto", "auto", "auto", "auto", "auto"], // Ajuste as larguras conforme necessário
            body: [
              ["Data", "Classificação", "Descrição", "Valor", "NF"], // Cabeçalho da tabela
              ...despezas.map((item) => [
                new Date(item.data).toLocaleString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour12: false,
                }),
                item.classificacao,
                item.descricao,
                item.valor,
                item.nf,
              ]), // Linhas da tabela
            ],
            layout: "auto", // Ajuste automático do layout da tabela
          },
        },
      ],
      pageMargins: [20, 20, 20, 20], // Configurar margens menores
    };

    pdfMake.createPdf(docDefinition).open();
  }
  function ImprimirDespezasSemNf() {
    var despesasSemNf = despezas.filter(
      (item) => item.nf === null || item.nf === undefined || item.nf === ""
    );
    var docDefinition = {
      content: [
        {
          text: `${new Date().toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
          })}`,
          fontSize: 10,
        },
        {
          text: `${descricao}`,
          style: { bold: true, alignment: "center" },
          fontSize: 18,
        },
        {
          text: `Total: R$${totalDespezas}`,
          style: { alignment: "right" },
          fontSize: 12,
        },
        {
          table: {
            headerRows: 1,
            widths: ["auto", "auto", "auto", "auto", "auto"], // Ajuste as larguras conforme necessário
            body: [
              ["Data", "Classificação", "Descrição", "Valor", "NF"], // Cabeçalho da tabela
              ...despesasSemNf.map((item) => [
                new Date(item.data).toLocaleString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour12: false,
                }),
                item.classificacao,
                item.descricao,
                item.valor,
                item.nf,
              ]), // Linhas da tabela
            ],
            layout: "auto", // Ajuste automático do layout da tabela
          },
        },
      ],
      pageMargins: [20, 20, 20, 20], // Configurar margens menores
    };

    pdfMake.createPdf(docDefinition).open();
  }
  function ImprimirDespezasComNf() {
    var despesasSemNf = despezas.filter((item) => item.nf);
    var docDefinition = {
      content: [
        {
          text: `${new Date().toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
          })}`,
          fontSize: 10,
        },
        {
          text: `${descricao}`,
          style: { bold: true, alignment: "center" },
          fontSize: 18,
        },
        {
          text: `Total: R$${totalDespezas}`,
          style: { alignment: "right" },
          fontSize: 12,
        },
        {
          table: {
            headerRows: 1,
            widths: ["auto", "auto", "auto", "auto", "auto"], // Ajuste as larguras conforme necessário
            body: [
              ["Data", "Classificação", "Descrição", "Valor", "NF"], // Cabeçalho da tabela
              ...despesasSemNf.map((item) => [
                new Date(item.data).toLocaleString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour12: false,
                }),
                item.classificacao,
                item.descricao,
                item.valor,
                item.nf,
              ]), // Linhas da tabela
            ],
            layout: "auto", // Ajuste automático do layout da tabela
          },
        },
      ],
      pageMargins: [20, 20, 20, 20], // Configurar margens menores
    };

    pdfMake.createPdf(docDefinition).open();
  }
  const handleCep = (value) => {
    value = value.replace(/\D/g, "");
    setCep(value);
    if (value.length >= 8) {
      axios({
        method: "get",
        url: `https://opencep.com/v1/${value}`,
      })
        .then((res) => {
          setLogradouro(res.data.logradouro);
          setBairro(res.data.bairro);
          setMunicipio(res.data.localidade);
          setNumero(res.data.numero);
          setUf(res.data.uf);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  async function pesquisar(value) {
    value = value.toUpperCase();
    let filteredDespesas;
    try {
      filteredDespesas = despezas.filter(
        (despesas) =>
          (despesas.data &&
            new Date(despesas.data)
              .toLocaleString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              })
              .includes(value)) ||
          (despesas.descricao &&
            despesas.descricao.toUpperCase().includes(value)) ||
          (despesas.classificacao &&
            despesas.classificacao.toUpperCase().includes(value)) ||
          (despesas.valor && despesas.valor.toUpperCase().includes(value)) ||
          (despesas.nf && despesas.nf.toUpperCase().includes(value))
      );

      setDespezasFiltro(filteredDespesas);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <div className="col-2">
          <Button
            variant="filled"
            onClick={voltar}
            color="rgba(54, 194, 41, 1)"
            leftSection={<IconArrowBackUp size={14} />}
          >
            Voltar
          </Button>
        </div>
        <Tabs color="green" radius="md" defaultValue="inicio">
          <Tabs.List>
            <Tabs.Tab value="inicio" leftSection={<IconFile size="1rem" />}>
              Inicio
            </Tabs.Tab>
            <Tabs.Tab value="Imagens" leftSection={<IconPhoto size="1rem" />}>
              Diário da Obra
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="inicio">
            {openEditDados ? (
              <div>
                <div className="row mt-3">
                  <div className="col-3">
                    <Input.Wrapper label="Data:">
                      <input
                        className="form-control"
                        type="date"
                        name=""
                        id=""
                        value={new Date(data).toISOString().split("T")[0]}
                        onChange={(e) => setData(e.target.value)}
                      />
                    </Input.Wrapper>
                  </div>
                  <div className="col">
                    <Input.Wrapper label="Descrição:">
                      <Input
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                      />
                    </Input.Wrapper>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <Input.Wrapper label="CEP:">
                      <Input
                        value={cep}
                        onChange={(e) => handleCep(e.target.value)}
                      />
                    </Input.Wrapper>
                  </div>
                  <div className="col">
                    <Input.Wrapper label="Logradouro:">
                      <Input
                        value={logradouro}
                        onChange={(e) => setLogradouro(e.target.value)}
                      />
                    </Input.Wrapper>
                  </div>
                  <div className="col">
                    <Input.Wrapper label="Bairro:">
                      <Input
                        value={bairro}
                        onChange={(e) => setBairro(e.target.value)}
                      />
                    </Input.Wrapper>
                  </div>
                  <div className="col">
                    <Input.Wrapper label="Municipio:">
                      <Input
                        value={municipio}
                        onChange={(e) => setMunicipio(e.target.value)}
                      />
                    </Input.Wrapper>
                  </div>
                  <div className="col">
                    <Input.Wrapper label="Número:">
                      <Input
                        value={numero}
                        onChange={(e) => setNumero(e.target.value)}
                      />
                    </Input.Wrapper>
                  </div>
                  <div className="col">
                    <Input.Wrapper label="UF:">
                      <Input
                        value={uf}
                        onChange={(e) => setUf(e.target.value)}
                      />
                    </Input.Wrapper>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <Textarea
                      label="Observação:"
                      value={observacao}
                      onChange={(e) => setObservacao(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-1">
                    {loadingAdicionar ? (
                      <Button onClick={(e) => Atualizar()} loading>
                        <IconPencilCheck size={15} />
                      </Button>
                    ) : (
                      <Button onClick={(e) => Atualizar()}>
                        <IconPencilCheck size={15} />
                      </Button>
                    )}
                  </div>
                  <div className="col-1">
                    <Button
                      onClick={(e) => setOpenEditDados(false)}
                      color="red"
                    >
                      <IconPencilX size={15} />
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="border rounded border-opacity-10 p-2 mt-2">
                {loading ? (
                  <div className="text-center mt-3">
                    <Loader color="green" />
                  </div>
                ) : (
                  <div>
                    <div className="row mt-3">
                      <div className="col-4">
                        <h5>
                          Data:
                          {moment.utc(data).format("DD/MM/YYYY")}
                        </h5>
                      </div>
                      <div className="col">
                        <h5>{descricao}</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {cep ? (
                          <iframe
                            width="960"
                            title="maps"
                            className="col-lg-12 col-md-12 col-sm-12"
                            height="300"
                            frameborder="0"
                            src={`https://www.google.com/maps/embed/v1/place?q=${cep}&key=AIzaSyCUmGFt13m5rHW370W81b1DC9q7nyLPsvo`}
                            allowfullscreen
                          ></iframe>
                        ) : (
                          <div>
                            {bairro ? (
                              <iframe
                                width="960"
                                className="col-lg-12 col-md-12 col-sm-12"
                                title="maps"
                                height="300"
                                frameborder="0"
                                src={`https://www.google.com/maps/embed/v1/place?q=${
                                  logradouro + numero + bairro + municipio
                                }&key=AIzaSyCUmGFt13m5rHW370W81b1DC9q7nyLPsvo`}
                                allowfullscreen
                              ></iframe>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">Cep: {cep}</div>
                      <div className="col">Bairro: {bairro}</div>
                      <div className="col">Municipio: {municipio}</div>
                      <div className="col">Numero: {numero}</div>
                      <div className="col">UF: {uf}</div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <h5>Observação: {observacao}</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Button onClick={(e) => setOpenEditDados(true)}>
                          <IconPencil size={15} />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="row mt-5">
              <div className="col">
                <Button
                  onClick={open}
                  leftSection={<IconPlus size={14} />}
                  variant="filled"
                  color="rgba(54, 194, 41, 1)"
                >
                  Adicionar Nova Despesa
                </Button>
                <Button
                  className="mx-2"
                  onClick={ImprimirDespezas}
                  leftSection={<IconFile size={14} />}
                  variant="filled"
                  color="green"
                >
                  Imprimir
                </Button>
                <Button
                  className="mx-2"
                  onClick={ImprimirDespezasSemNf}
                  leftSection={<IconFile size={14} />}
                  variant="filled"
                  color="green"
                >
                  Imprimir sem NF
                </Button>
                <Button
                  className="mx-2"
                  onClick={ImprimirDespezasComNf}
                  leftSection={<IconFile size={14} />}
                  variant="filled"
                  color="green"
                >
                  Imprimir com NF
                </Button>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                Total das despesas:{" "}
                {loading ? (
                  <div>
                    <Loader color="green" size="sm" />
                  </div>
                ) : (
                  <div>R${totalDespezas}</div>
                )}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <Input
                  leftSection={<IconSearch size={16} />}
                  onChange={(e) => pesquisar(e.target.value)}
                />
              </div>
            </div>
            <div className="row mt-3">
              {loading || loadingExcluir ? (
                <div className="text-center mt-3">
                  <Loader color="green" />
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  data={despezasFiltro ? despezasFiltro : []}
                  onRowClicked={handleRowClick}
                  direction="auto"
                  fixedHeaderScrollHeight="300px"
                  pagination
                  responsive
                  subHeaderAlign="right"
                  subHeaderWrap
                  highlightOnHover
                  pointerOnHover
                />
              )}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="Imagens">
            <Imagens />
          </Tabs.Panel>
        </Tabs>
      </div>
      <Modal
        opened={opened}
        onClose={handleCloseModal}
        title={openEditDespesas ? "Editar Despesa" : "Adicionar Despesa"}
        centered
      >
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Data:" withAsterisk>
              <input
                className="form-control"
                type="date"
                name=""
                id=""
                value={dataDespeza}
                onChange={(e) => setDataDespeza(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Descrição:" withAsterisk>
              <Input
                value={descricaoDespeza}
                onChange={(e) => setDescricaoDespeza(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <NativeSelect
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status:"
              data={["-------", "Pendente", "Pago"]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Data do Vencimento:">
              <input
                className="form-control"
                type="date"
                value={dataVencimento}
                onChange={(e) => setDataVencimento(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Data do Pagamento:">
              <input
                className="form-control"
                type="date"
                name=""
                id=""
                value={dataPagamento}
                onChange={(e) => setDataPagamento(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Tipo de Título:">
              <Input
                value={tipoTitulo}
                onChange={(e) => setTipoTitulo(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <NativeSelect
              value={formaPagamento}
              onChange={(e) => setFormaPagamento(e.target.value)}
              label="Forma de pagamento:"
              data={[
                "-------",
                "Dinheiro",
                "Cartão de Crédito",
                "Cartão de Débito",
                "Transferência Bancária",
                "Boleto Bancário",
                "Cheque",
                "Carnê",
                "Pagamento Digital",
                "PIX",
                "Outro",
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Nº Parcela:">
              <Input
                value={numeroParcela}
                onChange={(e) => setNumeroParcela(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Fornecedor/Cliente:">
              <Input
                value={fornecedorCliente}
                onChange={(e) => setFornecedorCliente(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <NativeSelect
              label="Classificação da Despesa:"
              data={["Despesa fixa", "Despesa corrente"]}
              value={classificacaoDespeza}
              onChange={(e) => setClassificacaoDespeza(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Valor:">
              <Input onChange={(e) => handleInputValor(e)} value={valor} />
            </Input.Wrapper>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Input.Wrapper label="NF:">
              <Input
                value={nf}
                onChange={(e) => setNf(e.target.value.replace(/[^0-9.]/g, ""))}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Textarea
              label="Observação:"
              value={observaocaoDespeza}
              onChange={(e) => setObservaocaoDespeza(e.target.value)}
            />
          </div>
        </div>
        <div className="row mt-3">
          {openEditDespesas ? (
            loadingAdicionar ? (
              <div>
                <Button
                  fullWidth
                  loading
                  onClick={AtualizarDespeza}
                  size="xs"
                  leftSection={<IconPlus size={14} />}
                  variant="filled"
                  color="rgba(54, 194, 41, 1)"
                >
                  Salvar
                </Button>
              </div>
            ) : (
              <Button
                fullWidth
                onClick={AtualizarDespeza}
                size="xs"
                leftSection={<IconPlus size={14} />}
                variant="filled"
                color="rgba(54, 194, 41, 1)"
              >
                Salvar
              </Button>
            )
          ) : (
            <div>
              {loadingAdicionar ? (
                <Button
                  fullWidth
                  size="xs"
                  loading
                  onClick={AdicionarDespeza}
                  leftSection={<IconPlus size={14} />}
                  variant="filled"
                  color="rgba(54, 194, 41, 1)"
                >
                  Adicionar
                </Button>
              ) : (
                <Button
                  fullWidth
                  size="xs"
                  onClick={AdicionarDespeza}
                  leftSection={<IconPlus size={14} />}
                  variant="filled"
                  color="rgba(54, 194, 41, 1)"
                >
                  Adicionar
                </Button>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ExibirDespesas;
