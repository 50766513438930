import { useEffect, useState } from "react";
import NavBar from "../navbar/NavBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Card, Loader, Progress, Text } from "@mantine/core";

function Banco() {
  const token = window.localStorage.getItem("x-access-token");
  const navigate = useNavigate();

  const [banco, setBanco] = useState("");
  const [loading, setLoading] = useState(true);

  function getBanco() {
    axios({
      method: "get",
      url: "https://api-scaf.onrender.com/api/utils",
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        var total = 0;
        res.data.forEach((element) => {
          total += parseFloat(element);
          console.log(element);
        });
        setBanco(total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar os dados !", "", "error");
      });
  }
  useEffect(() => {
    getBanco();
  }, []);
  return (
    <div>
      <NavBar />

      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <Card
          withBorder
          radius="md"
          padding="xl"
          bg="var(--mantine-color-body)"
        >
          <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
            tamanho em mb
          </Text>
          {loading ? (
            <div className="text-center mt-3">
              <Loader color="green" size="xs" type="dots"/>
            </div>
          ) : (
            <Text fz="lg" fw={500}>
              {banco} / 10.00
            </Text>
          )}

          <Progress
            value={(banco / 10.0) * 100}
            mt="md"
            size="lg"
            radius="xl"
            striped
            animated
          />
        </Card>
      </div>
    </div>
  );
}

export default Banco;
