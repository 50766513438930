import { useState } from "react";
import NavBar from "../navbar/NavBar";
import {
  Button,
  Input,
  NativeSelect,
  PasswordInput,
  Switch,
  Tooltip,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { IconArrowBackUp, IconPlus } from "@tabler/icons-react";

function Cadastrar() {
  const navigate = useNavigate();
  const token = window.localStorage.getItem("x-access-token");

  const [nome, setNome] = useState("");
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [perfil, setPerfil] = useState("Operador");
  const [ativo, setAtivo] = useState(false);
  const [alterarSenha, setAlterarSenha] = useState(false);
  const [loading, setLoading] = useState(false);

  const [focused, setFocused] = useState(false);

  function Cadastrar() {
    if (senha && senha.length < 8) {
      return Swal.fire(
        "A senha deve conter no mínimo 8 caracteres !",
        "",
        "error"
      );
    }
    if (!usuario) {
      return Swal.fire("Um nome de usuário deve ser informado !", "", "error");
    }

    setLoading(true);
    axios({
      method: "post",
      url: "https://api-scaf.onrender.com/api/user",
      data: {
        nome: nome,
        Username: usuario,
        Password: senha,
        Perfil: perfil,
        Ativo: ativo,
        AlterarSenha: alterarSenha,
      },
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setNome("");
        setUsuario("");
        setSenha("");
        setPerfil("");
        setAtivo("");
        setAlterarSenha("");
        navigate("/usuarios");
        setLoading(false);

        return Swal.fire("Usuário cadastrado com sucesso !", "", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }

  const voltar = () => {
    navigate("/usuarios");
  };

  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <Button
          variant="filled"
          onClick={voltar}
          color="rgba(54, 194, 41, 1)"
          leftSection={<IconArrowBackUp size={14} />}
        >
          Voltar
        </Button>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Nome:">
              <Input onChange={(e) => setNome(e.target.value)} value={nome} />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Usuário:" withAsterisk>
              <Input
                onChange={(e) => setUsuario(e.target.value)}
                value={usuario}
              />
            </Input.Wrapper>
          </div>
          <div className="row">
            <div className="col">
              <PasswordInput
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                label="Senha:"
                inputContainer={(children) => (
                  <Tooltip
                    label="A senha deve possuir no mínimo 8 caracteres"
                    position="top-start"
                    opened={focused}
                  >
                    {children}
                  </Tooltip>
                )}
              />
            </div>
            <div className="col">
              <NativeSelect
                value={perfil}
                onChange={(e) => setPerfil(e.target.value)}
                label="Perfil"
                data={["Operador", "Administrador"]}
              />
            </div>
            <div className="col mt-3">
              <Switch
                color="rgba(54, 194, 41, 1)"
                checked={ativo}
                label="Ativo ?"
                onChange={(e) => setAtivo(e.currentTarget.checked)}
              />
              <Switch
                color="rgba(54, 194, 41, 1)"
                checked={alterarSenha}
                className="mt-1"
                label="Alterar Senha ?"
                onChange={(e) => setAlterarSenha(e.currentTarget.checked)}
              />
            </div>
          </div>
          {loading ? (
            <Button
              leftSection={<IconPlus size={14} />}
              className="mt-3"
              fullWidth
              loading
              size="xs"
              onClick={Cadastrar}
              color="rgba(54, 194, 41, 1)"
            >
              Cadastrar
            </Button>
          ) : (
            <Button
              leftSection={<IconPlus size={14} />}
              className="mt-3"
              fullWidth
              size="xs"
              onClick={Cadastrar}
              color="rgba(54, 194, 41, 1)"
            >
              Cadastrar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Cadastrar;
