import { Link, useNavigate } from "react-router-dom";
import ImagemLogin from "../../assets/ScafLogo.webp";
import "../session/login.css";
import { useState } from "react";
import { Button } from "@mantine/core";
import axios from "axios";
import Swal from "sweetalert2";

function Login() {
  const navigate = useNavigate();

  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [loading, setLoading] = useState(false);

  function handleLogin(e) {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://api-scaf.onrender.com/api/Session",
      data: {
        username: login,
        password: senha,
      },
    })
      .then((res) => {
        if (!res.data.user.ativo) {
          Swal.fire("Usuário inativo !", "", "error");
          return navigate("/login", { replace: true });
        }
        window.localStorage.setItem("x-access-token", res.data.token);
        if (res.data.user.alterarSenha) {
          return navigate("/alterarsenha");
        }
        navigate("/", { replace: true });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }

  return (
    <div>
      <div>
        <section className="vh-100">
          <div className="container py-5 h-100">
            <div className="row d-flex align-items-center justify-content-center h-100">
              <div className="col-md-8 col-lg-7 col-xl-6">
                <img
                  src={ImagemLogin}
                  className="img-fluid"
                  alt="Logo Nexa"
                  style={{ width: "70%" }}
                />
              </div>
              <div className="col-md-7 col-lg-5 col-xl-5 ">
                <form>
                  <div className="form-floating  mb-4">
                    <input
                      value={login}
                      type="login"
                      id="login"
                      className="form-control form-control-lg"
                      placeholder="Usuário"
                      onChange={(e) => setLogin(e.target.value)}
                    />
                    <label className="form-label" htmlFor="login">
                      Usuário
                    </label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      value={senha}
                      type="password"
                      id="password"
                      className="form-control form-control-lg"
                      placeholder="Senha"
                      onChange={(e) => setSenha(e.target.value)}
                    />
                    <label className="form-label" htmlFor="password">
                      Senha
                    </label>
                  </div>
                  {loading ? (
                    <Button loading fullWidth color="rgba(54, 194, 41, 1)">
                      Entrar
                    </Button>
                  ) : (
                    <Button onClick={handleLogin} fullWidth color="rgba(54, 194, 41, 1)">
                      Entrar
                    </Button>
                  )}

                  <hr />

                  <p className="small text-muted">
                    Precisa de ajuda ?
                    <Link to="https://wa.me/5537999053128?text=Ol%C3%A1%2C+preciso+de+ajuda+no+sistema+Scaf">
                      {" "} Entrar em contato
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Login;
