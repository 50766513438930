import FullCalendar from "@fullcalendar/react";
import NavBar from "../navbar/NavBar";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useDisclosure } from "@mantine/hooks";
import { Button, Input, Loader, Modal, Textarea } from "@mantine/core";
import { useEffect, useState } from "react";
import { IconPlus } from "@tabler/icons-react";
import moment from "moment/moment";
import "moment-timezone";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ptLocale from "@fullcalendar/core/locales/pt-br";

moment.locale("pt-br");

function Agenda() {
  const token = window.localStorage.getItem("x-access-token");
  const navigate = useNavigate();

  const [
    openAdicionar,
    { open: openAdicionarModal, close: closeAdicionarModal },
  ] = useDisclosure(false);
  const [openEditar, { open: openEditarModal, close: closeEditarModal }] =
    useDisclosure(false);
  const [id, setId] = useState(0);
  const [data, setData] = useState("");
  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [eventos, setEventos] = useState([]);
  const [loadingCadastrar, setLoadingCadastrar] = useState(false);
  const [loadingAtualizar, setLoadingAtualizar] = useState(false);
  const [loadingDeletar, setLoadingDeletar] = useState(false);

  useEffect(() => {
    LimparDados();
  }, [openEditar, openAdicionar]);

  function LimparDados() {
    setTitulo("");
    setDescricao("");
    setId("");
  }
  function Cadastrar() {
    if (!titulo) {
      return Swal.fire("Informe um título !", "", "error");
    }
    setLoadingCadastrar(true);
    axios({
      method: "post",
      url: `https://api-scaf.onrender.com/api/agenda`,
      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        data,
        titulo,
        descricao,
      },
    })
      .then((res) => {
        Swal.fire("Evento cadastrado !", "", "success");
        LimparDados();
        GetEventos();
        setLoadingCadastrar(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingCadastrar(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao cadastrar eventos !", "", "error");
      });
  }

  function Atualizar() {
    if (!titulo) {
      return Swal.fire("Informe um título !", "", "error");
    }
    setLoadingAtualizar(true);
    axios({
      method: "put",
      url: `https://api-scaf.onrender.com/api/agenda`,
      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        id,
        data,
        titulo,
        descricao,
      },
    })
      .then((res) => {
        setLoadingAtualizar(false);
        Swal.fire("Evento atualizado !", "", "success");
        GetEventos();
      })
      .catch((err) => {
        console.log(err);
        setLoadingAtualizar(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao cadastrar eventos !", "", "error");
      });
  }

  function GetEventos() {
    axios({
      method: "get",
      url: `https://api-scaf.onrender.com/api/agenda`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        const eventosFormatados = res.data.map((evento) => ({
          title: evento.titulo,
          start: evento.data,
          id: evento.id.toString(),
        }));
        setEventos(eventosFormatados);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar eventos !", "", "error");
      });
  }

  function handleDateClick(arg) {
    setData(moment(arg.date).format("YYYY-MM-DD"));
    openAdicionarModal(true);
  }
  function handleEventClick(arg) {
    openEditarModal(true);
    axios({
      method: "get",
      url: `https://api-scaf.onrender.com/api/agenda/${arg.event.id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setId(arg.event.id);
        setData(res.data.data);
        setDescricao(res.data.descricao);
        setTitulo(res.data.titulo);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar eventos !", "", "error");
      });
  }
  function Deletar() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Deseja excluir o cadastro selecionado ?") === false) {
      return;
    }
    setLoadingDeletar(true);
    axios({
      method: "delete",
      url: `https://api-scaf.onrender.com/api/agenda/${id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        GetEventos();
        setLoadingDeletar(false);
        closeEditarModal(true)
        return Swal.fire(`Evento excluido com sucesso`, "", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoadingDeletar(false);
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }
  useEffect(() => {
    GetEventos();
  }, []);
  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          dateClick={(e) => handleDateClick(e)}
          eventClick={(e) => handleEventClick(e)}
          events={eventos}
          locale={ptLocale}
        />
      </div>
      <Modal
        opened={openAdicionar}
        onClose={closeAdicionarModal}
        title="Adicionar"
      >
        {moment(data).format("DD/MM/YYYY")}
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Titulo:">
              <Input
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Textarea
              label="Descrição:"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
          </div>
        </div>
        <div className="row mt-3">
          {loadingCadastrar ? (
            <Button
              fullWidth
              size="xs"
              leftSection={<IconPlus size={14} />}
              variant="filled"
              color="rgba(54, 194, 41, 1)"
              onClick={(e) => Cadastrar()}
              loading
            >
              Adicionar
            </Button>
          ) : (
            <Button
              fullWidth
              size="xs"
              leftSection={<IconPlus size={14} />}
              variant="filled"
              color="rgba(54, 194, 41, 1)"
              onClick={(e) => Cadastrar()}
            >
              Adicionar
            </Button>
          )}
        </div>
      </Modal>
      <Modal opened={openEditar} onClose={closeEditarModal} title="Editar">
        {moment(data).format("DD/MM/YYYY")}

        <div className="row">
          <div className="col">
            <Input.Wrapper label="Titulo:">
              <Input
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Textarea
              label="Descrição:"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
          </div>
        </div>
        {loadingDeletar ? (
          <button className="btn btn-danger ms-1 mt-2">
            <Loader size="xs" color="red" />
          </button>
        ) : (
          <button
            className="btn btn-danger ms-1 mt-2"
            onClick={(e) => Deletar()}
          >
            <i className="bi bi-trash"></i>
          </button>
        )}

        <div className="row mt-3">
          {loadingAtualizar ? (
            <Button
              fullWidth
              size="xs"
              leftSection={<IconPlus size={14} />}
              variant="filled"
              color="rgba(54, 194, 41, 1)"
              onClick={(e) => Atualizar()}
              loading
            >
              Atualizar
            </Button>
          ) : (
            <Button
              fullWidth
              size="xs"
              leftSection={<IconPlus size={14} />}
              variant="filled"
              color="rgba(54, 194, 41, 1)"
              onClick={(e) => Atualizar()}
            >
              Atualizar
            </Button>
          )}
        </div>
      </Modal>
      <div className="row mt-5"></div>
    </div>
  );
}

export default Agenda;
