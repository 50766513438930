import { useEffect, useState } from "react";
import NavBar from "../navbar/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Button, Input, NativeSelect, Switch } from "@mantine/core";
import { IconFileAnalytics } from "@tabler/icons-react";
import moment from "moment/moment";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function RelatorioContasAPagar() {
  const token = window.localStorage.getItem("x-access-token");
  const navigate = useNavigate();

  const [contas, setContas] = useState([]);
  const [status, setStatus] = useState("Todos");
  const [valor, setValor] = useState("");
  const [condicaoValor, setCondicaoValor] = useState("Maior que");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [loading, setLoading] = useState(true);

  function getContas() {
    axios({
      method: "get",
      url: "https://api-scaf.onrender.com/api/contasapagar",
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setContas(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar contas !", "", "error");
      });
  }

  useEffect(() => {
    getContas();
  }, []);

  function Imprimir() {
    var contasFiltro = contas;

    if (status === "Pago") {
      contasFiltro = contasFiltro.filter((item) => item.status === "Pago");
    }
    if (status === "Pendente") {
      contasFiltro = contasFiltro.filter((item) => item.status === "Pendente");
    }

    if (dataInicio && dataFim) {
      contasFiltro = contasFiltro.filter(
        (item) =>
          moment(item.dataVencimento).isSameOrAfter(
            moment(dataInicio).subtract(1, "days")
          ) &&
          moment(item.dataVencimento).isSameOrBefore(
            moment(dataFim).add(1, "days")
          )
      );
    }

    var docDefinition = {
      content: [
        {
          text: `${new Date().toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
          })}`,
          fontSize: 10,
        },
        {
          text: `Relatório Contas a Pagar`,
          style: { bold: true, alignment: "center" },
          fontSize: 18,
        },
        {
          table: {
            headerRows: 1,
            widths: [
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
            ], // Ajuste as larguras conforme necessário
            body: [
              [
                "ID",
                "Data Vencimento",
                "Data Pagamento",
                "Status",
                "Fornecedor/Cliente",
                "Valor",
                "Forma Pagamento",
                "N° Parcela",
              ], // Cabeçalho da tabela
              ...contasFiltro.map((item) => [
                item.id,
                new Date(item.dataVencimento).toLocaleString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour12: false,
                }),
                new Date(item.dataPagamento).toLocaleString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour12: false,
                }),
                item.status,
                item.fornecedorCliente,
                item.valor,
                item.formaPagamento,
                item.numeroParcela,
              ]),
            ],
            layout: "auto",
          },
        },
      ],
      pageMargins: [20, 20, 20, 20], // Configurar margens menores
    };

    pdfMake.createPdf(docDefinition).open();
  }

  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <h4>Contas a Pagar</h4>
        <div className="row">
          <div className="col-2">
            <NativeSelect
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status:"
              data={["Todos", "Pendente", "Pago"]}
            />
          </div>

          <div className="col">
            <Input.Wrapper label="Data Inicio:">
              <input
                className="form-control"
                type="date"
                name=""
                id=""
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Data Fim:">
              <input
                className="form-control"
                type="date"
                name=""
                id=""
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        {loading ? (
          <Button
            leftSection={<IconFileAnalytics size={14} />}
            className="mt-3"
            fullWidth
            loading
            size="xs"
            onClick={Imprimir}
            color="rgba(54, 194, 41, 1)"
          >
            imprimir
          </Button>
        ) : (
          <Button
            leftSection={<IconFileAnalytics size={14} />}
            className="mt-3"
            fullWidth
            size="xs"
            onClick={Imprimir}
            color="rgba(54, 194, 41, 1)"
          >
            imprimir
          </Button>
        )}
      </div>
    </div>
  );
}

export default RelatorioContasAPagar;
