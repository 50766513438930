import {
  Button,
  FileInput,
  Image,
  Input,
  Loader,
  Modal,
  Select,
} from "@mantine/core";
import { IconPencilCheck, IconPlus, IconSearch } from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "./img-projetos.css";
import moment from "moment/moment";
import "moment-timezone";
import generateRandomString from "../../utility/generateRandomString";
import { useDisclosure } from "@mantine/hooks";
moment.locale("pt-br");

function Imagens() {
  const token = window.localStorage.getItem("x-access-token");
  const navigate = useNavigate();
  const params = useParams();

  const [imagens, setImagens] = useState([]);
  const [filtroimagens, setFiltroImagens] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [loadingCadastrar, setLoadingCadastrar] = useState(false);
  const [loadingExcluir, setLoadingExcluir] = useState(false);

  const [loadingCadastrarStep, setLoadingCadastrarStep] = useState(false);
  const [loadingExcluirStep, setLoadingExcluirStep] = useState(false);

  const [steps, setSteps] = useState([]);
  const [step, setStep] = useState("");

  const [tituloStep, setTituloStep] = useState("");

  const [validEdit, setValidEdit] = useState(false);

  const [
    openAdicionar,
    { open: openAdicionarModal, close: closeAdicionarModal },
  ] = useDisclosure(false);
  const [openEditar, { open: openEditarModal, close: closeEditarModal }] =
    useDisclosure(false);

  function Cadastrar() {
    setLoadingCadastrar(true);
    if (!selectedFile) {
      setLoadingCadastrar(false);
      return Swal.fire("Imagem não selecionada !", "", "error");
    }

    const formData = new FormData();
    formData.append("Arquivo", selectedFile);
    formData.append("Nome", titulo);
    formData.append("Data", moment().format());
    formData.append("ProjetoId", params.id);
    var stepId;
    if (step) {
      stepId = steps.find((value) => value.titulo === step).id;
      formData.append("StepProjetoId", stepId);
    }

    axios({
      method: "post",
      url: `https://api-scaf.onrender.com/api/Projetos/imagem`,
      data: formData,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        Swal.fire("Imagem cadastrada !", "", "success");
        setTitulo("");
        setSelectedFile(null);
        getImagens();
        setLoadingCadastrar(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingCadastrar(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao cadastrar imagens !", "", "error");
      });
  }

  function CadastrarStep() {
    if (!tituloStep) {
      return Swal.fire("Informe um título !", "", "error");
    }
    setLoadingCadastrarStep(true);
    axios({
      method: "post",
      url: `https://api-scaf.onrender.com/api/stepprojeto`,
      data: {
        titulo: tituloStep,
        projetoId: params.id, 
        date: moment().toDate(),
      },
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        Swal.fire("Etapa cadastrado !", "", "success");
        setLoadingCadastrarStep(false);
        setTituloStep("");
        getSteps();
      })
      .catch((err) => {
        console.log(err);
        setLoadingCadastrarStep(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao cadastrar passo !", "", "error");
      });
  }

  function getImagens() {
    var stepId = 0;
    if (step) {
      stepId = steps.find((value) => value.titulo === step).id;
    }
    axios({
      method: "get",
      url: `https://api-scaf.onrender.com/api/Projetos/imagem/byproject/${params.id}/step/${stepId}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setImagens(
          res.data.sort((a, b) => new Date(b.data) - new Date(a.data))
        );
        setFiltroImagens(
          res.data.sort((a, b) => new Date(b.data) - new Date(a.data))
        );
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar imagens !", "", "error");
      });
  }
  function ExcluirImagens(id) {
    var msg = generateRandomString();
    var confim = prompt(`Digite os caracteres para excluir: ${msg} `);
    if (confim !== msg) {
      return;
    }
    setLoadingExcluir(true);
    axios({
      method: "delete",
      url: `https://api-scaf.onrender.com/api/Projetos/imagem/${id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        Swal.fire("Imagem excluida !", "", "success");
        getImagens();
        setLoadingExcluir(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingExcluir(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao excluir imagem !", "", "error");
      });
  }
  async function pesquisar(value) {
    value = value.toUpperCase();
    let filteredImagens;
    try {
      filteredImagens = imagens.filter(
        (imagens) =>
          (imagens.nome && imagens.nome.toUpperCase().includes(value)) ||
          (imagens.data && imagens.data.toUpperCase().includes(value))
      );

      setFiltroImagens(filteredImagens);
    } catch (e) {
      console.log(e);
    }
  }

  function getSteps() {
    axios({
      method: "get",
      url: `https://api-scaf.onrender.com/api/StepProjeto/byproject/${params.id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setSteps(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar categorias !", "", "error");
      });
  }
  function atualizarSteps() {
    if (!tituloStep) {
      return Swal.fire("Informe um título !", "", "error");
    }
    var stepId;
    stepId = steps.find((value) => value.titulo === step).id;
    setLoadingCadastrarStep(true);
    axios({
      method: "put",
      url: `https://api-scaf.onrender.com/api/StepProjeto/`,
      data: {
        id: stepId,
        titulo: tituloStep,
        projetoId: params.id,
        date: moment().toDate(),
      },
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        Swal.fire("Etapa atualizada !", "", "success");
        setLoadingCadastrarStep(false);
        setStep(null);
        getSteps();
        closeEditarModal(true);
      })
      .catch((err) => {
        console.log(err);
        setLoadingCadastrarStep(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao atualizar etapa !", "", "error");
      });
  }
  function excluirStep() {

    // eslint-disable-next-line no-restricted-globals
    if (confirm("Deseja excluir o cadastro selecionado e todas as imagens relacionadas ?") === false) {
      setLoadingExcluir(false);
      return;
    }
    var stepId;
    stepId = steps.find((value) => value.titulo === step).id;
    setLoadingExcluirStep(true);
    axios({
      method: "delete",
      url: `https://api-scaf.onrender.com/api/StepProjeto/${stepId}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        Swal.fire("Etapa excluida !", "", "success");
        setLoadingExcluirStep(false);
        setStep(null);
        getSteps();
        closeEditarModal(true);
      })
      .catch((err) => {
        console.log(err);
        setLoadingExcluirStep(false);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao excluir etapa !", "", "error");
      });
  }
  function handleOpenAtualizar() {
    setTituloStep(step);
    openEditarModal(true);
  }

  useEffect(() => {
    getSteps();
    getImagens();
  }, []);

  useEffect(() => {
    step ? setValidEdit(true) : setValidEdit(false);
    getImagens();
  }, [step]);
  useEffect(() => {
    setTituloStep("");
  }, [openAdicionar]);

  return (
    <div>
      <div className="row mt-3">
        <div className="col-3">
          <FileInput
            label="Adicionar Foto"
            placeholder="Clique aqui para selecionar"
            value={selectedFile}
            onChange={(e) => setSelectedFile(e)}
          />
          {loadingCadastrar ? (
            <Button
              leftSection={<IconPlus size={14} />}
              className="mt-1"
              loading
              size="xs"
              color="rgba(54, 194, 41, 1)"
              onClick={(e) => Cadastrar()}
            >
              Cadastrar
            </Button>
          ) : (
            <Button
              leftSection={<IconPlus size={14} />}
              className="mt-1"
              size="xs"
              color="rgba(54, 194, 41, 1)"
              onClick={(e) => Cadastrar()}
            >
              Cadastrar
            </Button>
          )}
        </div>
        <div className="col-4">
          <Input.Wrapper label="Título:" withAsterisk>
            <Input onChange={(e) => setTitulo(e.target.value)} value={titulo} />
          </Input.Wrapper>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {steps.length > 0 ? (
            <Select
              value={step}
              label="Etapa"
              placeholder="Escolha uma Etapa"
              data={steps.map((step) => step.titulo)}
              onChange={setStep}
              searchable
              nothingFoundMessage="Não encontrado ..."
            />
          ) : (
            <div>
              <Select
                label="Etapa"
                placeholder="Nenhum encontrado"
                searchable
                disabled
                nothingFoundMessage="Não encontrado ..."
              />
            </div>
          )}
        </div>

        <div className="col mt-4">
          {validEdit ? (
            <Button
              className="mt-1"
              size="xs"
              onClick={(e) => handleOpenAtualizar()}
            >
              <IconPencilCheck size={14} />
            </Button>
          ) : (
            <Button className="mt-1" size="xs" disabled>
              <IconPencilCheck size={14} />
            </Button>
          )}

          <Button
            className="mt-1 mx-2"
            size="xs"
            color="rgba(54, 194, 41, 1)"
            onClick={openAdicionarModal}
          >
            <IconPlus size={14} />
          </Button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <Input
            leftSection={<IconSearch size={16} />}
            onChange={(e) => pesquisar(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-2 d-flex align-items-center justify-content-center text-center">
        <div className="row">
          {filtroimagens.map((item, index) => (
            <div className="col-12 mt-4" key={index}>
              <h4>{item.nome}</h4>
              <p>
                {moment(item.data).format("L")}

                {loadingExcluir ? (
                  <Button
                    variant="filled"
                    color="red"
                    size="xs"
                    className="mx-3"
                    loading
                    onClick={(e) => ExcluirImagens(item.id)}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                ) : (
                  <Button
                    variant="filled"
                    color="red"
                    size="xs"
                    className="mx-3"
                    onClick={(e) => ExcluirImagens(item.id)}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                )}
              </p>
              <img
                src={`data:image/png;base64,${item.binario}`}
                style={{ width: "30.5rem" }}
                className="img-fluid img-projetos rounded-3"
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
      <Modal
        opened={openAdicionar}
        onClose={closeAdicionarModal}
        title="Adicionar"
      >
        <Input.Wrapper label="Título:" withAsterisk>
          <Input
            onChange={(e) => setTituloStep(e.target.value)}
            value={tituloStep}
          />
        </Input.Wrapper>
        <div className="row">
          <div className="col">
            {loadingCadastrarStep ? (
              <Button
                leftSection={<IconPlus size={14} />}
                onClick={CadastrarStep}
                className="mt-3"
                fullWidth
                loading
                size="xs"
                color="rgba(54, 194, 41, 1)"
              >
                Cadastrar
              </Button>
            ) : (
              <Button
                leftSection={<IconPlus size={14} />}
                onClick={CadastrarStep}
                className="mt-3"
                fullWidth
                size="xs"
                color="rgba(54, 194, 41, 1)"
              >
                Cadastrar
              </Button>
            )}
          </div>
        </div>
      </Modal>
      <Modal opened={openEditar} onClose={closeEditarModal} title="Atualizar">
        <Input.Wrapper label="Título:" withAsterisk>
          <Input
            onChange={(e) => setTituloStep(e.target.value)}
            value={tituloStep}
          />
        </Input.Wrapper>
        <div className="row">
          <div className="col">
            {loadingExcluirStep ? (
              <button className="btn btn-danger ms-1 mt-2">
                <Loader size="xs" color="red" />
              </button>
            ) : (
              <button
                className="btn btn-danger ms-1 mt-2"
                onClick={(e) => excluirStep()}
              >
                <i className="bi bi-trash"></i>
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {loadingCadastrarStep ? (
              <Button
                leftSection={<IconPlus size={14} />}
                onClick={atualizarSteps}
                className="mt-3"
                fullWidth
                loading
                size="xs"
                color="rgba(54, 194, 41, 1)"
              >
                Atualizar
              </Button>
            ) : (
              <Button
                leftSection={<IconPlus size={14} />}
                onClick={atualizarSteps}
                className="mt-3"
                fullWidth
                size="xs"
                color="rgba(54, 194, 41, 1)"
              >
                Atualizar
              </Button>
            )}
          </div>
        </div>
      </Modal>
      <div className="row mt-5"></div>
    </div>
  );
}

export default Imagens;
