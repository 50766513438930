import NavBar from "../navbar/NavBar";
import ImagemLogin from "../../assets/ScafLogo.webp";
import { Button, Card, Loader, Progress, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment/moment";
import "@mantine/core/styles.css";
import "@mantine/core/styles/Card.css";
import classes from "./Card.module.css";
moment.locale("pt-br");

function Inicio() {
  const token = window.localStorage.getItem("x-access-token");
  const [contasAPagar, setContasAPagar] = useState([]);
  const [totalAPagar, setTotalAPagar] = useState(0);
  const [totalAPagarvencendoHoje, setTotalAPagarvencendoHoje] = useState(0);
  const [pendenteAPagar, setPendenteAPagar] = useState(0);

  const [contasAReceber, setContasAReceber] = useState([]);
  const [totalAReceber, setTotalAReceber] = useState(0);
  const [pendenteAReceber, setPendenteAReceber] = useState(0);
  const [totalARecebervencendoHoje, setTotalARecebervencendoHoje] = useState(0);

  const [loadingAPagar, setLoadingAPagar] = useState(true);
  const [loadingAPagarVencendo, setLoadingAPagarVencendo] = useState(true);

  const [loadingAReceber, setLoadingAReceber] = useState(true);
  const [loadingAVencendo, setLoadingAVencendo] = useState(true);

  const [totalAPagarvencendo3dias, setTotalAPagarvencendo3dias] = useState(0);
  const [totalAPagarvencendo1semana, setTotalAPagarvencendo1semana] =
    useState(0);
  const [totalARecebervencendo3dias, setTotalARecebervencendo3dias] =
    useState(0);
  const [totalARecebervencendo1semana, setTotalARecebervencendo1semana] =
    useState(0);
  const navigate = useNavigate();

  function getContasAPagar() {
    axios({
      method: "get",
      url: `https://api-scaf.onrender.com/api/despesas/status/Todos`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setContasAPagar(res.data);
        var totalPendentes = 0;
        var totalAPagarvencendoHoje = 0;
        var totalPendentes3dias = 0;
        var totalPendentes1semana = 0;

        var pendentes = res.data.filter(
          (x) =>
            x.status === "Pendente" &&
            moment(x.dataVencimento).format("YYYY-MM-DD") >=
              moment().format("YYYY-MM-DD")
        );
        for (let index = 0; index < pendentes.length; index++) {
          if (pendentes[index].valor.replace(/\./g, "").replace(",", ".")) {
            totalPendentes += parseFloat(
              pendentes[index].valor.replace(/\./g, "").replace(",", ".")
            );
          }

          var diferencaDias = moment(pendentes[index].dataVencimento).diff(
            moment().format("YYYY-MM-DD"),
            "days"
          );

          if (diferencaDias <= 3) {
            totalPendentes3dias++;
          }
          if (diferencaDias <= 7) {
            totalPendentes1semana++;
          }

          if (diferencaDias == 0) {
            totalAPagarvencendoHoje++;
          }
        }

        setPendenteAPagar(FormataDinheiro(totalPendentes));
        setTotalAPagarvencendoHoje(totalAPagarvencendoHoje);
        setTotalAPagarvencendo3dias(totalPendentes3dias);
        setTotalAPagarvencendo1semana(totalPendentes1semana);
        setLoadingAPagar(false);
        setLoadingAPagarVencendo(false);
      })

      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar os dados !", "", "error");
      });
  }
  function getContasAReceber() {
    axios({
      method: "get",
      url: `https://api-scaf.onrender.com/api/contasareceber/status/Todos`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setContasAReceber(res.data);
        var totalARecebervencendoHoje = 0;
        var totalPendentes3dias = 0;
        var totalPendentes1semana = 0;

        var totalPendentes = 0;
        var pendentes = res.data.filter(
          (x) =>
            x.status === "Pendente" &&
            x.dataVencimento >= moment().format("YYYY-MM-DD")
        );
        for (let index = 0; index < pendentes.length; index++) {
          totalPendentes += parseFloat(
            pendentes[index].valor.replace(/\./g, "").replace(",", ".")
          );

          var diferencaDias = moment(res.data[index].dataVencimento).diff(
            moment().format("YYYY-MM-DD"),
            "days"
          );

          if (diferencaDias <= 3) {
            totalPendentes3dias++;
          }
          if (diferencaDias <= 7) {
            totalPendentes1semana++;
          }

          if (diferencaDias == 0) {
            totalARecebervencendoHoje++;
          }
        }

        setPendenteAReceber(FormataDinheiro(totalPendentes));
        setTotalARecebervencendoHoje(totalARecebervencendoHoje);
        setTotalARecebervencendo3dias(totalPendentes3dias);
        setTotalARecebervencendo1semana(totalPendentes1semana);

        setLoadingAReceber(false);
        setLoadingAVencendo(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar os dados !", "", "error");
      });
  }

  useEffect(() => {
    getContasAPagar();
    getContasAReceber();
  }, []);
  function FormataDinheiro(valor) {
    if (typeof valor === "string") {
      // Converte a string para um número de ponto flutuante
      valor = parseFloat(valor.replace(/\./g, "").replace(",", "."));
    } else {
      // Se não for uma string, assume que já é um número
      valor = parseFloat(valor);
    }

    if (!isNaN(valor)) {
      // Se o valor for um número válido
      return valor.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
    } else {
      return "";
    }
  }
  return (
    <div>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col mt-3">
            <Card
              withBorder
              radius="md"
              padding="xl"
              bg="var(--mantine-color-body)"
            >
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                Vencendo hoje
              </Text>
              <Text fz="lg" fw={500}>
                {loadingAPagarVencendo ? (
                  <div className="text-center mt-3">
                    <Loader color="red" size="xs" type="dots" />
                  </div>
                ) : (
                  <div>{totalAPagarvencendoHoje}</div>
                )}
              </Text>

              <Button
                onClick={(e) => navigate("/contasapagar")}
                variant="light"
                color="rgba(255, 0, 0, 1)"
                fullWidth
                mt="md"
                radius="md"
              >
                Ver todos
              </Button>
            </Card>
          </div>
          <div className="col mt-3">
            <Card
              withBorder
              radius="md"
              padding="xl"
              bg="var(--mantine-color-body)"
            >
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                Vencendo em 3 dias
              </Text>
              <Text fz="lg" fw={500}>
                {loadingAPagarVencendo ? (
                  <div className="text-center mt-3">
                    <Loader color="orange" size="xs" type="dots" />
                  </div>
                ) : (
                  <div>{totalAPagarvencendo3dias}</div>
                )}
              </Text>
              <Button
                onClick={(e) => navigate("/contasapagar")}
                variant="light"
                color="rgba(255, 106, 0, 1)"
                fullWidth
                mt="md"
                radius="md"
              >
                Ver todos
              </Button>
            </Card>
          </div>
          <div className="col mt-3">
            <Card
              withBorder
              radius="md"
              padding="xl"
              bg="var(--mantine-color-body)"
            >
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                Vencendo em 1 Semana
              </Text>
              <Text fz="lg" fw={500}>
                {loadingAPagarVencendo ? (
                  <div className="text-center mt-3">
                    <Loader color="yellow" size="xs" type="dots" />
                  </div>
                ) : (
                  <div>{totalAPagarvencendo1semana}</div>
                )}
              </Text>
              <Button
                onClick={(e) => navigate("/contasapagar")}
                variant="light"
                color="rgba(240, 204, 0, 1)"
                fullWidth
                mt="md"
                radius="md"
              >
                Ver todos
              </Button>
            </Card>
          </div>
          <div className="col mt-3">
            <Card
              className={classes.Card}
              withBorder
              radius="md"
              padding="xl"
              bg="var(--mantine-color-body)"
            >
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                Valor total contas a pagar
              </Text>
              <Text fz="lg" fw={500}>
                {loadingAPagar ? (
                  <div className="text-center mt-3">
                    <Loader color="green" size="xs" type="dots" />
                  </div>
                ) : (
                  <div>R${pendenteAPagar}</div>
                )}
              </Text>
              <Button
                variant="light"
                onClick={(e) => navigate("/contasapagar")}
                color="green"
                fullWidth
                mt="md"
                radius="md"
              >
                Ver todos
              </Button>
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col mt-3">
            <Card
              withBorder
              radius="md"
              padding="xl"
              bg="var(--mantine-color-body)"
            >
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                Vencendo hoje
              </Text>
              <Text fz="lg" fw={500}>
                {loadingAVencendo ? (
                  <div className="text-center mt-3">
                    <Loader color="red" size="xs" type="dots" />
                  </div>
                ) : (
                  <div>{totalARecebervencendoHoje}</div>
                )}
              </Text>
              <Button
                onClick={(e) => navigate("/contasareceber")}
                variant="light"
                color="rgba(255, 0, 0, 1)"
                fullWidth
                mt="md"
                radius="md"
              >
                Ver todos
              </Button>
            </Card>
          </div>
          <div className="col mt-3">
            <Card
              withBorder
              radius="md"
              padding="xl"
              bg="var(--mantine-color-body)"
            >
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                Vencendo em 3 dias
              </Text>
              <Text fz="lg" fw={500}>
                {loadingAVencendo ? (
                  <div className="text-center mt-3">
                    <Loader color="orange" size="xs" type="dots" />
                  </div>
                ) : (
                  <div>{totalARecebervencendo3dias}</div>
                )}
              </Text>
              <Button
                onClick={(e) => navigate("/contasareceber")}
                variant="light"
                color="rgba(255, 106, 0, 1)"
                fullWidth
                mt="md"
                radius="md"
              >
                Ver todos
              </Button>
            </Card>
          </div>
          <div className="col mt-3">
            <Card
              withBorder
              radius="md"
              padding="xl"
              bg="var(--mantine-color-body)"
            >
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                Vencendo em 1 semana
              </Text>
              <Text fz="lg" fw={500}>
                {loadingAVencendo ? (
                  <div className="text-center mt-3">
                    <Loader color="yellow" size="xs" type="dots" />
                  </div>
                ) : (
                  <div>{totalARecebervencendo1semana}</div>
                )}
              </Text>
              <Button
                onClick={(e) => navigate("/contasareceber")}
                variant="light"
                color="rgba(240, 204, 0, 1)"
                fullWidth
                mt="md"
                radius="md"
              >
                Ver todos
              </Button>
            </Card>
          </div>
          <div className="col mt-3">
            <Card
              withBorder
              radius="md"
              padding="xl"
              bg="var(--mantine-color-body)"
            >
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                Valor total contas a receber
              </Text>
              <Text fz="lg" fw={500}>
                {loadingAReceber ? (
                  <div className="text-center mt-3">
                    <Loader color="green" size="xs" type="dots" />
                  </div>
                ) : (
                  <div>R${pendenteAReceber}</div>
                )}
              </Text>
              <Button
                onClick={(e) => navigate("/contasareceber")}
                variant="light"
                color="green"
                fullWidth
                mt="md"
                radius="md"
              >
                Ver todos
              </Button>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <img src={ImagemLogin} alt="" style={{ width: "25rem" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inicio;
