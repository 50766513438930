import {
  Button,
  Input,
  Loader,
  Modal,
  NativeSelect,
  Notification,
  NumberInput,
  Textarea,
} from "@mantine/core";
import NavBar from "../navbar/NavBar";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  IconArrowBackUp,
  IconPencilCheck,
  IconPlus,
} from "@tabler/icons-react";

function AtualizarAReceber() {
  const token = window.localStorage.getItem("x-access-token");
  const navigate = useNavigate();
  const params = useParams();

  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [dataVencimento, setDataVencimento] = useState("");
  const [dataPagamento, setDataPagamento] = useState("");
  const [tipoTitulo, setTipoTitulo] = useState("");
  const [valor, setValor] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [numeroParcela, setNumeroParcela] = useState("");
  const [fornecedorCliente, setFornecedorCliente] = useState("");
  const [observacao, setObservacao] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingAtualizar, setLoadingAtualizar] = useState(false);

  function Atualizar() {
    setLoadingAtualizar(true);
    axios({
      method: "put",
      url: "https://api-scaf.onrender.com/api/contasareceber",
      headers: {
        authorization: "Bearer " + token,
      },
      data: {
        id,
        status,
        dataVencimento,
        dataPagamento,
        tipoTitulo,
        valor,
        formaPagamento,
        numeroParcela,
        fornecedorCliente,
        observacao,
      },
    })
      .then((res) => {
        console.log(res.data);
        setLoadingAtualizar(false);

        Swal.fire("Conta atualizada !", "", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoadingAtualizar(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao atualizar conta !", "", "error");
      });
  }
  function InputValor(valor) {
    valor = valor + "";
    valor = parseInt(valor.replace(/[\D]+/g, ""));
    valor = valor + "";
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    setValor(valor);
    if (valor == "NaN") setValor("");
  }

  const voltar = () => {
    navigate("/contasareceber");
  };
  useEffect(() => {
    axios({
      method: "get",
      url: `https://api-scaf.onrender.com/api/contasareceber/${params.id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setId(res.data.id);
        setStatus(res.data.status);
        if (res.data.dataVencimento) {
          setDataVencimento(
            new Date(res.data.dataVencimento).toISOString().split("T")[0]
          );
        }
        if (res.data.dataPagamento) {
          setDataPagamento(
            new Date(res.data.dataPagamento).toISOString().split("T")[0]
          );
        }

        setTipoTitulo(res.data.tipoTitulo);
        setValor(res.data.valor);
        setFormaPagamento(res.data.formaPagamento);
        setNumeroParcela(res.data.numeroParcela);
        setFornecedorCliente(res.data.fornecedorCliente);
        setObservacao(res.data.observacao);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar conta !", "", "error");
      });
  }, []);
  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <Button
          variant="filled"
          onClick={voltar}
          color="rgba(54, 194, 41, 1)"
          leftSection={<IconArrowBackUp size={14} />}
        >
          Voltar
        </Button>

        {loading ? (
          <div className="text-center mt-3">
            <Loader color="green" />
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="col">
                <NativeSelect
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Status:"
                  data={["-------", "Pendente", "Recebido"]}
                />
              </div>
              <div className="col">
                <Input.Wrapper label="Data do Vencimento:">
                  <input
                    className="form-control"
                    type="date"
                    value={dataVencimento}
                    onChange={(e) => setDataVencimento(e.target.value)}
                  />
                </Input.Wrapper>
              </div>
              <div className="col">
                <Input.Wrapper label="Data do Pagamento:">
                  <input
                    className="form-control"
                    type="date"
                    name=""
                    id=""
                    value={dataPagamento}
                    onChange={(e) => setDataPagamento(e.target.value)}
                  />
                </Input.Wrapper>
              </div>
              <div className="col">
                <Input.Wrapper label="Tipo de Título:">
                  <Input
                    value={tipoTitulo}
                    onChange={(e) => setTipoTitulo(e.target.value)}
                  />
                </Input.Wrapper>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Input.Wrapper label="Valor:">
                  <Input
                    value={valor}
                    onChange={(e) => InputValor(e.target.value)}
                  />
                </Input.Wrapper>
              </div>
              <div className="col">
                <NativeSelect
                  value={formaPagamento}
                  onChange={(e) => setFormaPagamento(e.target.value)}
                  label="Forma de pagamento:"
                  data={[
                    "-------",
                    "Dinheiro",
                    "Cartão de Crédito",
                    "Cartão de Débito",
                    "Transferência Bancária",
                    "Boleto Bancário",
                    "Cheque",
                    "Carnê",
                    "Pagamento Digital",
                    "PIX",
                    "Outro",
                  ]}
                />
              </div>
              <div className="col">
                <Input.Wrapper label="Nº Parcela:">
                  <Input
                    value={numeroParcela}
                    onChange={(e) =>
                      setNumeroParcela(e.target.value)
                    }
                  />
                </Input.Wrapper>
              </div>
              <div className="col">
                <Input.Wrapper label="Fornecedor/Cliente:">
                  <Input
                    value={fornecedorCliente}
                    onChange={(e) => setFornecedorCliente(e.target.value)}
                  />
                </Input.Wrapper>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Textarea
                  label="Observação:"
                  value={observacao}
                  onChange={(e) => setObservacao(e.target.value)}
                />
              </div>
            </div>
            {loadingAtualizar ? (
              <Button
                leftSection={<IconPencilCheck size={14} />}
                onClick={Atualizar}
                className="mt-3"
                fullWidth
                loading
                size="xs"
                color="rgba(54, 194, 41, 1)"
              >
                Atualizar
              </Button>
            ) : (
              <Button
                leftSection={<IconPencilCheck size={14} />}
                onClick={Atualizar}
                className="mt-3"
                fullWidth
                size="xs"
                color="rgba(54, 194, 41, 1)"
              >
                Atualizar
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AtualizarAReceber;
