import NavBar from "../navbar/NavBar";
import {
  Button,
  Input,
  Loader,
  Modal,
  Notification,
  NumberInput,
  Textarea,
} from "@mantine/core";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IconPlus, IconSearch } from "@tabler/icons-react";

function InicioEstoque() {
  const token = window.localStorage.getItem("x-access-token");
  const navigate = useNavigate();
  const [produtos, setProdutos] = useState([]);
  const [produtosFiltro, setProdutosFiltro] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      name: "Data Atualização",
      selector: (row) =>
        new Date(row.dataAtualizacao).toLocaleString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",

          hour12: false,
        }),
      filter: true,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      filter: true,
      sortable: true,
    },
    {
      name: "Nome",
      selector: (row) => row.nome,
      filter: true,
      sortable: true,
    },
    {
      name: "Qtd",
      selector: (row) => row.qtd,
      filter: true,
      sortable: true,
    },

    {
      name: "Responsável",
      selector: (row) => row.responsavel,
      filter: true,
      sortable: true,
    },
    {
      cell: (row) => (
        <div>
          <button
            className="btn btn-danger ms-1"
            onClick={(e) => deletarProduto(row.id)}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  function getProdutos() {
    axios({
      method: "get",
      url: "https://api-scaf.onrender.com/api/produto",
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setProdutos(res.data);
        setProdutosFiltro(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar produtos !", "", "error");
      });
  }
  function deletarProduto(id) {
    setLoading(true);
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Deseja excluir o cadastro selecionado ?") === false) {
      setLoading(false);

      return;
    }
    console.log(id);
    axios({
      method: "delete",
      url: `https://api-scaf.onrender.com/api/produto/${id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        getProdutos();
        setLoading(false);

        return Swal.fire(`Produto excluido com sucesso`, "", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }
  const handleRowClick = (row) => {
    navigate(`/estoque/atualizar/${row.id}`);
  };
  const abrirCadastrar = () => {
    navigate("/estoque/cadastrar");
  };
  useEffect(() => {
    getProdutos();
  }, []);
  async function pesquisar(value) {
    value = value.toUpperCase();
    let filteredProdutos;
    try {
      filteredProdutos = produtos.filter(
        (produtos) =>
          (produtos.status && produtos.status.toUpperCase().includes(value)) ||
          (produtos.dataAtualizacao &&
            new Date(produtos.dataAtualizacao)
              .toLocaleString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              })
              .includes(value)) ||
          (produtos.nome && produtos.nome.toUpperCase().includes(value)) ||
          (produtos.qtd && produtos.qtd.toUpperCase().includes(value)) ||
          (produtos.responsavel &&
            produtos.responsavel.toUpperCase().includes(value))
      );

      setProdutosFiltro(filteredProdutos);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <h4>Estoque ({produtos.length})</h4>
        <div className="row my-3">
          <div className="col">
            <Button
              leftSection={<IconPlus size={14} />}
              variant="filled"
              onClick={abrirCadastrar}
              color="rgba(54, 194, 41, 1)"
            >
              Adicionar Novo
            </Button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <Input
              leftSection={<IconSearch size={16} />}
              onChange={(e) => pesquisar(e.target.value)}
            />
          </div>
        </div>
        {loading ? (
          <div className="text-center mt-3">
            <Loader color="green" />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={produtosFiltro}
            onRowClicked={handleRowClick}
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
            highlightOnHover
            pointerOnHover
          />
        )}
      </div>
    </div>
  );
}

export default InicioEstoque;
