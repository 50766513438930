import { Link, useNavigate } from "react-router-dom";
import ImagemLogin from "../../assets/ScafLogo.webp";
import "../session/login.css";
import { useState } from "react";
import { Button } from "@mantine/core";
import axios from "axios";
import Swal from "sweetalert2";

function AlterarSenha() {
  const navigate = useNavigate();
  const token = window.localStorage.getItem("x-access-token");

  const [senhaConfirm, setSenhaConfirm] = useState("");
  const [senha, setSenha] = useState("");
  const [loading, setLoading] = useState(false);

  function Alterar() {
    if (senha && senha.length < 8) {
      return Swal.fire(
        "A senha deve conter no mínimo 8 caracteres !",
        "",
        "error"
      );
    }
    setLoading(true);
    if (!senha || !senhaConfirm) {
      setLoading(false);
      return Swal.fire("As senhas não informadas !", "", "error");
    }
    if (senha !== senhaConfirm) {
      setLoading(false);
      return Swal.fire("As senhas não conferem !", "", "error");
    }
    axios({
      method: "post",
      url: `https://api-scaf.onrender.com/api/user/changepassword/${senha}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setLoading(false);
        Swal.fire("Senha alterada com sucesso !", "", "success");
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao alterar a senha do usuário !", "", "error");
      });
  }

  return (
    <div>
      <div>
        <section className="vh-100">
          <div className="container py-5 h-100">
            <div className="row d-flex align-items-center justify-content-center h-100">
              <div className="col-md-8 col-lg-7 col-xl-6">
                <img
                  src={ImagemLogin}
                  className="img-fluid"
                  alt="Logo Nexa"
                  style={{ width: "70%" }}
                />
              </div>
              <div className="col-md-7 col-lg-5 col-xl-5 ">
                <div
                  className="alert alert-success d-flex align-items-center"
                  role="alert"
                >
                  <i className="bi bi-exclamation-diamond-fill"></i>
                  <div className="mx-2">
                    A senha deve possuir no mínimo 8 caracteres
                  </div>
                </div>
                <form>
                  <div className="form-floating  mb-4">
                    <input
                      value={senhaConfirm}
                      type="password"
                      id="password"
                      className="form-control form-control-lg"
                      placeholder="Usuário"
                      onChange={(e) => setSenhaConfirm(e.target.value)}
                    />
                    <label className="form-label" htmlFor="login">
                      Senha
                    </label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      value={senha}
                      type="password"
                      id="password"
                      className="form-control form-control-lg"
                      placeholder="Senha"
                      onChange={(e) => setSenha(e.target.value)}
                    />
                    <label className="form-label" htmlFor="password">
                      Confirmação Senha
                    </label>
                  </div>
                  {loading ? (
                    <Button fullWidth loading color="rgba(54, 194, 41, 1)">
                      Alterar
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      onClick={Alterar}
                      color="rgba(54, 194, 41, 1)"
                    >
                      Alterar
                    </Button>
                  )}

                  <hr />
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AlterarSenha;
