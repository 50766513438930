import { useEffect, useState } from "react";
import NavBar from "../navbar/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Button, Input, NativeSelect, Switch } from "@mantine/core";
import { IconFileAnalytics } from "@tabler/icons-react";
import moment from "moment/moment";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function RelatorioProjeto() {
  const token = window.localStorage.getItem("x-access-token");
  const navigate = useNavigate();

  const [projetos, setProjetos] = useState([]);
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [valor, setValor] = useState("");
  const [condicaoValor, setCondicaoValor] = useState("Maior que");
  const [loading, setLoading] = useState(true);

  function getProjetos() {
    axios({
      method: "get",
      url: "https://api-scaf.onrender.com/api/projetos",
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setProjetos(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response.status === 401) {
          navigate("/login");
        }
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro ao buscar contas !", "", "error");
      });
  }

  useEffect(() => {
    getProjetos();
  }, []);
  function FormataDinheiro(valor) {
    if (typeof valor === "string") {
      // Converte a string para um número de ponto flutuante
      valor = parseFloat(valor.replace(/\./g, "").replace(",", "."));
    } else {
      // Se não for uma string, assume que já é um número
      valor = parseFloat(valor);
    }

    if (!isNaN(valor)) {
      // Se o valor for um número válido
      return valor.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
    } else {
      return "";
    }
  }
  function CalculaTotalDespezas(despeza) {
    var total = 0;
    if (despeza) {
      for (let index = 0; index < despeza.length; index++) {
        if (despeza[index].valor) {
          total += parseFloat(
            despeza[index].valor.replace(/\./g, "").replace(",", ".")
          );
        }
      }

      return FormataDinheiro(total);
    }
  }
  function Imprimir() {
    var projetosFiltro = projetos;

    projetosFiltro.forEach((projeto) => {
      projeto.totalDespezas = CalculaTotalDespezas(projeto.despezas);
    });

    if (dataInicio && dataFim) {
      projetosFiltro = projetosFiltro.filter(
        (item) =>
          moment(item.data).isSameOrAfter(
            moment(dataInicio).subtract(1, "days")
          ) && moment(item.data).isSameOrBefore(moment(dataFim).add(1, "days"))
      );
    }

    var docDefinition = {
      content: [
        {
          text: `${new Date().toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
          })}`,
          fontSize: 10,
        },
        {
          text: `Relatório Projetos`,
          style: { bold: true, alignment: "center" },
          fontSize: 18,
        },
        {
          table: {
            headerRows: 1,
            widths: [
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
            ], // Ajuste as larguras conforme necessário
            body: [
              [
                "ID",
                "Data",
                "Descrição",
                "CEP",
                "Bairro",
                "Município",
                "Logradouro",
                "Total Despesas",
              ], // Cabeçalho da tabela
              ...projetosFiltro.map((item) => [
                item.id,
                new Date(item.data).toLocaleString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour12: false,
                }),
                item.descricao,
                item.cep,
                item.bairro,
                item.municipio,
                item.logradouro,
                item.totalDespezas,
              ]),
            ],
            layout: "auto",
          },
        },
      ],
      pageMargins: [20, 20, 20, 20], // Configurar margens menores
    };

    pdfMake.createPdf(docDefinition).open();
  }

  function InputValor(valor) {
    valor = valor + "";
    valor = parseInt(valor.replace(/[\D]+/g, ""));
    valor = valor + "";
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    setValor(valor);
    if (valor == "NaN") setValor("");
  }
  return (
    <div>
      <NavBar />
      <div
        className="container col-sm-3  rounded-4 mt-4 p-3 col-xs-6 col-sm-6 col-md-6 col-lg-7 "
        style={{ backgroundColor: "white" }}
      >
        <h4>Projetos</h4>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Data Inicio:">
              <input
                className="form-control"
                type="date"
                name=""
                id=""
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Data Fim:">
              <input
                className="form-control"
                type="date"
                name=""
                id=""
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        {loading ? (
          <Button
            leftSection={<IconFileAnalytics size={14} />}
            className="mt-3"
            fullWidth
            loading
            size="xs"
            onClick={Imprimir}
            color="rgba(54, 194, 41, 1)"
          >
            imprimir
          </Button>
        ) : (
          <Button
            leftSection={<IconFileAnalytics size={14} />}
            className="mt-3"
            fullWidth
            size="xs"
            onClick={Imprimir}
            color="rgba(54, 194, 41, 1)"
          >
            imprimir
          </Button>
        )}
      </div>
    </div>
  );
}

export default RelatorioProjeto;
